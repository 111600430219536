import { useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Lottie from 'react-lottie-player';
import animationData from '../../assets/icons/confirmation.json';

const ModelDescriptionSection = ({
    modelId,
    productType,
    modelData,
    setModelData,
    uploadedImages,
    inputRef,
    eyeWear = false
}) => {
    const { getToken } = useAuth();
    const navigate = useNavigate();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const submitModelDescription = async () => {
        setSubmitLoading(true);
        try {
            const token = await getToken({ template: 'token' });
            const formData = new FormData();
            formData.append('model_id', modelId);

            if (productType === 'person') {
                formData.append('person_pet_name', modelData.person_pet_name);
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/submit-model-description`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response) {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/trigger-training?model_id=${modelId}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    navigate('/train-new');
                  
                }
            } else {
                if (productType !== 'product') {
                    formData.append('person_pet_name', modelData.person_pet_name);
                } else {
                    formData.append('product_name', modelData.productName.trim());
                }

                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/submit-model-description`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response && eyeWear) {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/trigger-training?model_id=${modelId}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setShowConfirmation(true);
                    setTimeout(() => {
                        navigate('/models');
                    }, 8000);
                } else if (response && !eyeWear) {
                    navigate(`/model-image-captions/${modelId}`);
                }
            }
            setSubmitLoading(false);
        } catch (error) {
            setSubmitLoading(false);
            console.error('Error submitting model description:', error);
        }
    };

    if (showConfirmation) {
        return (
            <div className="flex flex-col justify-center items-center h-full">
                <Lottie
                    loop={false}
                    animationData={animationData}
                    play
                    style={{ width: 225, height: 225 }}
                />
                <p className="text-center mt-8 font-bold">
                    Model submission successful! Redirecting to "Your Models"...
                </p>
            </div>
        );
    }

    return (
        <div className="flex flex-col grow">
            <div className="flex grow h-full items-center flex-col py-8">
                {eyeWear ?
                    <div className="flex w-screen justify-center gap-4  mb-4 px-4 ">
                        <div className="flex   overflow-x-scroll  gap-4   px-4 mr-4 ">
                            {uploadedImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Uploaded ${index + 1}`}
                                    className="w-40 object-contain bg-gray-200 rounded-lg"
                                />
                            ))}
                        </div>
                    </div>
                    :
                    <div className="relative w-[210px] aspect-1">
                        {uploadedImages.slice(0, 3).map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Uploaded ${index + 1}`}
                                className={`absolute object-cover rounded-lg border-[2px] border-white
                                ${index === 0 ? 'z-30 h-[208px] w-[208px] apsect-1 object-cover' : ' w-[116px] h-[116px]'}
                                ${index === 1 ? ' -left-20 top-4 z-40' : ''}
                                ${index === 2 ? 'bottom-4 -right-[6rem]' : ''}`}
                            />
                        ))}
                    </div>}
                {productType !== 'product' ? (
                    <div className="flex w-full justify-center">
                        <div className="mb-4 w-1/2">
                            <label className="text-md font-bold text-gray-700">
                                <div>Your model name</div>
                                <div className="font-normal text-sm">
                                    Use this name to refer to your model in prompts
                                </div>
                            </label>
                            <input
                                ref={inputRef}
                                value={modelData.person_pet_name}
                                onChange={e => setModelData({ ...modelData, person_pet_name: e.target.value })}
                                className="w-full font-bold p-4 mt-4 border-[1px] rounded-md border-gray-400"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="flex w-1/2 gap-2 px-2">
                        <div className="mb-4 w-full">
                            <label className="text-md font-bold text-gray-700 mb-6">
                                <div>Your model name</div>
                                <div className="font-normal text-sm">
                                    Use this name to refer to your model in prompts
                                </div>
                            </label>
                            <input
                                ref={inputRef}
                                value={modelData.productName}
                                onChange={e => setModelData({ ...modelData, productName: e.target.value })}
                                className="w-full font-bold mt-4 p-4 border-[1px] rounded-md border-gray-400"
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className="flex w-full justify-center">
                <div
                    className={`px-12 ${submitLoading ? "bg-gray-500 cursor-not-allowed" : "bg-black cursor-pointer"} text-white font-bold p-[.75rem] m-5 text-center rounded-3xl `}
                    disabled={submitLoading}
                    onClick={submitModelDescription}
                >
                    {eyeWear ? "🚀 Submit your model 🚀" : productType === 'person' ? '🚀 Submit your model 🚀' : 'Proceed >'}
                </div>
            </div>
        </div>
    );
};

export default ModelDescriptionSection;