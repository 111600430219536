import React, { useEffect, useState, useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SignInButton, SignOutButton, useAuth, useUser, useOrganizationList, useOrganization } from '@clerk/clerk-react';
import { ChevronDown } from 'lucide-react';
import useCredits from '../hooks/useCredits';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isSignedIn, isLoaded } = useAuth();
    const { user } = useUser();
    const { organizationList } = useOrganizationList();
    const { setActive } = useOrganization();
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { credits, fetchCredits } = useCredits();
    const [localCredits, setLocalCredits] = useState(null);

    // Update local credits when credits change
    useEffect(() => {
        if (credits.current !== null) {
            setLocalCredits(credits.current);
        }
    }, [credits.current]);

    // Profile image effect
    useEffect(() => {
        if (isSignedIn && user) {
            if (user.profileImageUrl) {
                setProfileImageUrl(user.profileImageUrl);
            } else if (user.imageUrl) {
                setProfileImageUrl(user.imageUrl);
            } else {
                setProfileImageUrl(null);
            }
        }
    }, [isSignedIn, user]);

    // Refresh only credits data every 5 seconds
    useEffect(() => {
        if (isSignedIn) {
            const intervalId = setInterval(async () => {
                await fetchCredits();
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [isSignedIn, fetchCredits]);

    // Organization effect
    useEffect(() => {
        if (organizationList && organizationList.length > 0) {
            const activeOrg = organizationList[0];
            setActive(activeOrg.id);
        }
    }, [organizationList, setActive]);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    return (
        <header className="flex relative justify-center items-center py-4 px-6 border-b bg-black text-white">
            <h1 className="text-2xl font-bold absolute left-0 px-6">FOOSH</h1>
            <nav className="">
                <ul className="flex space-x-6">
                    {[
                        { name: 'Models', path: '/models' },
                        { name: 'Studio', path: '/studio', path2: "/" },
                        { name: 'Gallery', path: '/gallery' },
                    ].map((item) => (
                        <li key={item.name}>
                            <Link
                                to={item.path}
                                className={`font-[400] ${
                                    location.pathname === item.path || location.pathname === item?.path2
                                        ? 'text-white'
                                        : 'text-white opacity-30'
                                }`}
                            >
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="absolute right-0 px-6 flex min-w-12 items-center space-x-4 text-white font-[400]">
                {isLoaded && isSignedIn && (
                    <>
                        {localCredits !== null && (
                            <div className="ml-4 text-sm font-[400] flex items-center">
                                <span>{localCredits} credits left</span>
                                {localCredits < 1000 && (
                                    <>
                                        <span> | </span>
                                        <span
                                            onClick={() => navigate('/billing')}
                                            className={`ml-2 cursor-pointer ${
                                                localCredits < 100 ? 'text-red-500' : 'text-yellow-500'
                                            }`}
                                        >
                                            Add more credits
                                        </span>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}
                {isLoaded && (
                    isSignedIn ? (
                        <div className="relative">
                            <button
                                onClick={toggleDropdown}
                                className="flex items-center space-x-2 focus:outline-none"
                            >
                                {profileImageUrl ? (
                                    <img src={profileImageUrl} alt="Profile" className="w-8 h-8 rounded-full" />
                                ) : (
                                    <svg className="w-8 h-8 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            fillRule="evenodd"
                                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                )}
                                <span className="text-sm font-[400]">{user.fullName}</span>
                                <ChevronDown size={16} />
                            </button>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                                    <Link
                                        to="/billing"
                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 font-bold"
                                        onClick={() => setIsDropdownOpen(false)}
                                    >
                                        Billing
                                    </Link>
                                    <SignOutButton>
                                        <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 font-bold">
                                            Sign Out
                                        </button>
                                    </SignOutButton>
                                </div>
                            )}
                        </div>
                    ) : (
                        <SignInButton />
                    )
                )}
            </div>
        </header>
    );
};

export default Header;