import { useState, useEffect } from 'react';

const AspectRatioComponent = ({ aspectRatio, setAspectRatio }) => {
  const [dimensions, setDimensions] = useState({ width: '1.25rem', height: '1.875rem' });

  useEffect(() => {
    const [width, height] = aspectRatio.split(':').map(Number);
    const BASE_SIZE = 1.25; // Base size of 1.25rem

    let finalWidth, finalHeight;

    if (width >= height) {
      // Landscape or square
      finalWidth = BASE_SIZE;
      finalHeight = (BASE_SIZE / width) * height;
    } else {
      // Portrait
      finalHeight = BASE_SIZE;
      finalWidth = (BASE_SIZE / height) * width;
    }

    setDimensions({
      width: `${finalWidth}rem`,
      height: `${finalHeight}rem`
    });
  }, [aspectRatio]);

  return (
    <div className="flex items-center gap-1 !px-4 aspect-ratio-select">
      <div
        className="rounded-sm bg-gray-200"
        style={{
          width: dimensions.width,
          height: dimensions.height
        }}
      ></div>
      <select
        value={aspectRatio}
        onChange={e => {
          const newAspectRatio = e.target.value;
          setAspectRatio(newAspectRatio);
          localStorage.setItem('aspectRatioFoosh', newAspectRatio);
        }}
        className="focus:outline-none"
      >
        <option value="4:3">4:3</option>
        <option value="3:4">3:4</option>
        <option value="1:1">1:1</option>
        <option value="16:9">16:9</option>
        <option value="9:16">9:16</option>
      </select>
    </div>
  );
};

export default AspectRatioComponent;