import React, { useState, useEffect } from 'react';
import { Globe, MapPin } from 'lucide-react';
import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import XPayView from './XPayView';
import PaymentView from './PaymentView';

const UnifiedPaymentView = () => {
  const [selectedPaymentType, setSelectedPaymentType] = useState('global');
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchSubscriptionHistory = async () => {
      try {
        const token = await getToken({template: 'token'});
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions-history`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        
        if (response.data.success && response.data.subscription) {
          setSubscriptionHistory(response.data.subscription);
          
          // Set initial payment type based on subscription mode
          if (response.data.subscription.mode === 'xpay') {
            setSelectedPaymentType('global');
          } else if (response.data.subscription.mode === 'rzp') {
            setSelectedPaymentType('india');
          }
        }
      } catch (error) {
        console.error('Error fetching subscription history:', error);
        toast.error('Failed to load subscription details');
      }
    };

    fetchSubscriptionHistory();
  }, [getToken]);

  const handlePaymentTypeChange = (type) => {
    setSelectedPaymentType(type);
  };

  const getActiveView = () => {
    if (subscriptionHistory?.mode) {
      return subscriptionHistory.mode === 'xpay' ? <XPayView /> : <PaymentView />;
    }
    return selectedPaymentType === 'global' ? <XPayView /> : <PaymentView />;
  };

  return (
    <div className="max-w-6xl mx-auto">
      {!subscriptionHistory?.mode && (
        <div className="flex justify-center mb-8 p-4">
          <div className="bg-gray-100 p-2 rounded-lg inline-flex">
            <button
              onClick={() => handlePaymentTypeChange('global')}
              className={`flex items-center px-6 py-3 rounded-md transition-all ${
                selectedPaymentType === 'global'
                  ? 'bg-white shadow-md text-black'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <Globe className="w-5 h-5 mr-2" />
              <span className="font-semibold">Global</span>
            </button>
            <button
              onClick={() => handlePaymentTypeChange('india')}
              className={`flex items-center px-6 py-3 rounded-md transition-all ${
                selectedPaymentType === 'india'
                  ? 'bg-white shadow-md text-black'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              <MapPin className="w-5 h-5 mr-2" />
              <span className="font-semibold">India</span>
            </button>
          </div>
        </div>
      )}

      {getActiveView()}
    </div>
  );
};

export default UnifiedPaymentView;