import React, { useState, useEffect } from 'react';

import SelectedImage from '../../components/SelectedImage';
import { useLocation } from 'react-router-dom';
import AllShotsView from './AllShotsView';
import SubHeader from './SubHeader';
import LikedUpscalesView from './LikedUpscalesView';

const GalleryView = ({ setCompletedUpscale, setUpscaleQueue }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTabQuery = queryParams.get('activeTab');
  const [activeTab, setActiveTab] = useState('liked');
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    if (activeTabQuery) {
      setActiveTab(activeTabQuery);
    }
  }, [activeTabQuery]);

  return (
    <div className="w-full h-full  flex flex-col overflow-y-scroll">
      <SelectedImage
        selectedImage={selectedImage}
        onClickCancel={() => setSelectedImage(null)}
      />
      <div className="flex flex-1 flex-col w-full">
        <SubHeader activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === 'all' ? (
          <AllShotsView
            setSelectedImage={setSelectedImage}
            setUpscaleQueue={setUpscaleQueue}
            setCompletedUpscale={setCompletedUpscale}
          />
        ) : (
          <LikedUpscalesView
            key={activeTab}
            activeTab={activeTab}
            setSelectedImage={setSelectedImage}
            setUpscaleQueue={setUpscaleQueue}
            setCompletedUpscale={setCompletedUpscale}
          />
        )}
      </div>
    </div>
  );
};

export default GalleryView;
