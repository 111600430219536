import React, { useState, useEffect } from 'react';
// import { Globe, MapPin } from 'lucide-react';
import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import XPayView from './XPayView';
import PaymentView from './PaymentView';
import Loader from '../assets/icons/loader.svg';
const UnifiedPaymentView = () => {
  const [selectedPaymentType, setSelectedPaymentType] = useState('global');
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const { getToken } = useAuth();
  const [loading, setLoading] = useState(true);

  console.log('Component mounted');

  useEffect(() => {
    console.log('Effect running');
    const fetchSubscriptionHistory = async () => {
      try {
        const token = await getToken({ template: 'token' });
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions-history`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.success && response.data.subscription) {
          setSubscriptionHistory(response.data.subscription);

          // Set initial payment type based on subscription mode
          if (response.data.subscription.mode === 'xpay') {
            setSelectedPaymentType('global');
          } else if (response.data.subscription.mode === 'rzp') {
            setSelectedPaymentType('india');
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching subscription history:', error);
        toast.error('Failed to load subscription details');
        setLoading(false);
      }finally{
        setLoading(false);
      }
    };

    fetchSubscriptionHistory();
    return () => console.log('Effect cleanup');
  }, []);

  const handlePaymentTypeChange = type => {
    setSelectedPaymentType(type);
  };

  const getActiveView = () => {
    if (subscriptionHistory?.mode) {
      return subscriptionHistory.mode === 'xpay' ? (
        <XPayView />
      ) : (
        <PaymentView />
      );
    }
    return selectedPaymentType === 'global' ? <XPayView /> : <PaymentView />;
  };
  if (loading) {
    return (
      <div className="w-full text-xl font-bold flex h-full justify-center items-center">
        <img className="h-20" src={Loader} alt="loader" />
      </div>
    );
  }
  return (
    <div className="flex flex-col flex-1 w-full h-full ">
  {subscriptionHistory && (subscriptionHistory.sub1  || subscriptionHistory.sub2||subscriptionHistory.sub3) && <div className="w-full flex justify-center text-lg py-4 border-b-[1px]">
 <span className="font-semibold">  {`Total credits left: ${subscriptionHistory.total_credits } `}</span>   &nbsp;|&nbsp;   Monthly credits: {subscriptionHistory.subscription_credits},  Topped up credits: {(subscriptionHistory.total_credits)-(subscriptionHistory.subscription_credits)}
   </div>}
    <div className=" flex flex-col h-full max-w-6xl mx-auto ">
      {!subscriptionHistory?.mode && (
        <div className="flex justify-center mb-8 p-4">
          <div className="bg-[#F0F0F0] rounded-full   inline-flex">
            <button
              onClick={() => handlePaymentTypeChange('global')}
              className={`flex items-center px-16 py-3 rounded-full transition-all ${
                selectedPaymentType === 'global'
                  ? 'bg-black shadow-md text-white'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {/* <Globe className="w-5 h-5 mr-2" /> */}
              <span className="font-normal">Global</span>
            </button>
            <button
              onClick={() => handlePaymentTypeChange('india')}
              className={`flex items-center px-16 py-3 rounded-full transition-all ${
                selectedPaymentType === 'india'
                  ? 'bg-black shadow-md text-white'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {/* <MapPin className="w-5 h-5 mr-2" /> */}
              <span className="font-normal">India</span>
            </button>
          </div>
        </div>
      )}

      {getActiveView()}
    </div>
    </div>
  );
};

export default UnifiedPaymentView;
