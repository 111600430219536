import React from 'react';
import upscaleOnWay from '../assets/icons/upscale_lottie.gif';
import upscaleComplete from '../assets/icons/UpScaleDoneToast@3x.png'

const UpscaleTracker = ({ upscaleQueue, completedUpscale }) => {
  const currentDomain = window.location.origin;

  return (
    <div
      className={`fixed bottom-0 border-[1px] border-[#80808061] right-4 px-4 py-2 pr-6 bg-white rounded-t-lg shadow-lg transform transition-all  duration-1000 ease-in-out
            ${(upscaleQueue.length === 0 && completedUpscale.length === 0)
          ? 'translate-y-full opacity-0 pointer-events-none'
          : ' translate-y-0 opacity-100'
        }`}
    >
      <div className={`  font-bold text-[0.75rem] pr-8 `}>
        <div className={`${upscaleQueue.length === 0 ? "hidden" : "flex items-center gap-1"} rounded-lg `}>
          <img className="w-6 h-6" src={upscaleOnWay} alt="in Pro" />  <div className="">{upscaleQueue.length} AI {upscaleQueue.length === 1 ? "Upscale" : "Upscales"} in progress  |  Takes  ~5 min </div>
        </div>

        <div className={`${completedUpscale.length === 0 ? "hidden" : "flex items-center gap-1"} rounded-lg`}>
          <img className="w-6 h-6 p-1 " src={upscaleComplete} alt="in Pro" />
          <div className="flex gap-1">{completedUpscale.length}  AI {completedUpscale.length === 1 ? "Upscale" : "Upscales"} ready.
            <div className="underline cursor-pointer"
              onClick={() => window.open(`${currentDomain}/gallery?activeTab=upscales`, '_blank')}>VIEW IN GALLERY</div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default UpscaleTracker;

