import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreditCard, Camera, ZoomIn } from 'lucide-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import useCredits from '../hooks/useCredits';
import { useAuth } from '@clerk/clerk-react';
import CreditAddModelBag from '../assets/icons/AddModelCreditSystem@3x.jpg';
import CreatShotCreditSystem from '../assets/icons/CreateShotCreditSystem@3x.png';
import UpscaleShotCreditSystem from '../assets/icons/UpscaleShotCreditSystem@3x.png';

const PaymentSuccessView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchCredits } = useCredits();
  const { getToken } = useAuth();
  const [isVerifying, setIsVerifying] = useState(true);
  const [verificationComplete, setVerificationComplete] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get('amount');
  const type = searchParams.get('type');
  const credits = searchParams.get('credits');

  const formatAmount = amount => {
    const amountNum = Number(amount);
    if (amountNum === 1020 || amountNum === 4250) {
      return `₹${amountNum}`;
    }
    return `$${amountNum}`;
  };

  useEffect(() => {
    let isSubscribed = true;

    const verifyAndUpdateCredits = async () => {
      if (amount && credits) {
        if (isSubscribed) {
          setVerificationComplete(true);
        }
        setIsVerifying(false);
        return;
      }
      const token = await getToken();
      if (!token) {
        console.error('No authentication token available');
        navigate('/payment-failure');
        return;
      }
      const xIntentId =
        localStorage.getItem('xpay_intent_id') ||
        searchParams.get('xpay_intent_id');

      if (!xIntentId) {
        console.log('No xPay intent ID found, checking URL parameters');
        if (amount && credits) {
          if (isSubscribed) {
            await fetchCredits();
            // toast.success('Credits updated successfully!');
            setVerificationComplete(true);
          }
        } else {
          console.error('Missing required payment parameters');
          navigate('/payment-failure');
        }
        setIsVerifying(false);
        return;
      }

      try {
        console.log('Verifying payment status for:', xIntentId);

        if (!isSubscribed) return;

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/xpay-payment-status/${xIntentId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('Payment verification response:', response.data);

        if (!isSubscribed) return;

        if (response.data.status === 'success') {
          await fetchCredits();
          //toast.success('Credits updated successfully!');
          setVerificationComplete(true);
        } else {
          console.error('Payment verification failed:', response.data);
          toast.error('Failed to verify payment');
          navigate('/payment-failure');
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        if (isSubscribed) {
          toast.error('Error verifying payment');
          navigate('/payment-failure');
        }
      } finally {
        localStorage.removeItem('xpay_intent_id');
        localStorage.removeItem('xpay_amount');
        localStorage.removeItem('xpay_type');

        if (isSubscribed) {
          setIsVerifying(false);
        }
      }
    };

    verifyAndUpdateCredits();

    return () => {
      isSubscribed = false;
    };
  }, [getToken, navigate, fetchCredits, amount, credits]);

  const creditOptions = [
    { name: 'Training a model', credits: 800, icon: CreditAddModelBag },
    { name: 'Generate an image', credits: 7, icon: CreatShotCreditSystem },
    { name: 'Upscale an image', credits: 30, icon: UpscaleShotCreditSystem },
  ];

  if (isVerifying) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4">Verifying payment...</p>
        </div>
      </div>
    );
  }

  if (!verificationComplete && !isVerifying) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
          <p className="text-xl text-red-600">Payment verification failed</p>
          <button
            className="mt-4 w-full bg-black text-white py-3 rounded-lg"
            onClick={() => navigate('/xpay')}
          >
            Return to payment page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col  items-center justify-center flex-1 relative overflow-hidden">
      {/* <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center"> */}
      {/* <h1 className="text-3xl font-bold mb-4 text-green-600">
          🐠 Just keep Fooshin'! 🐠
        </h1>
        
        <p className="text-xl font-semibold mb-6">
          Payment Successful!
        </p> */}

      {/* <p className="mb-4">
          Your {type === 'oneTime' ? 'one-time payment' : 'subscription'} of {formatAmount(amount)} has been processed successfully.
        </p> */}

      <p className="text-5xl font-semibold mb-2">{credits} credits added!</p>
<p className="text-sm mb-4">These credits don’t expire</p>
      <div className="flex flex-col mt-4  border-b-[1px] ">
            {/* <div className="font-bold text-lg">How credits work</div> */}
            <div className="grid grid-cols-3 gap-4 mb-4">
              {creditOptions.map((option, index) => (
                <div key={index} className="flex items-center bg-white p-4 ">
                  <img className="w-16" src={option.icon} alt=""></img>
                  <div className="p-2">
                    <p className="text-gray-600 text-sm">{option.name}</p>
                    <p className=" font-semibold text-xl">
                      {option.credits} credits
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

      {/* <p className="text-sm italic mb-6">
          PS: Your credits never expire!
        </p> */}

      <button
        className="flex p-8 text-xl px-40 font-semibold mt-10 bg-black text-white py-3 rounded-lg mb-4 hover:bg-gray-800 transition-colors z-10"
        onClick={() => navigate('/studio')}
      >
        Continue making epic shots
      </button>
      {/* </div> */}
      <div
        className="absolute overflow-hidden opacity-0 shadow-lg -bottom-32 w-[233px] h-[233px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '-14deg', animationDelay: '0.2s' }}
      > <img className="w-full h-full object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success3.png" alt=""/></div>
      <div
        className="absolute overflow-hidden shadow-lg opacity-0 -bottom-10 -left-24 w-[335px] h-[335px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '16deg', animationDelay: '0.4s' }}
      > <img className="w-full h-full  object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success1.png" alt=""/></div>
      <div
        className="absolute overflow-hidden opacity-0 -bottom-24 -right-24 w-[335px] h-[335px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '-20deg', animationDelay: '0.6s' }}
      > <img className="w-full h-full  object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success5.png" alt=""/></div>
      <div
        className="absolute overflow-hidden opacity-0 -bottom-24 left-80 w-[127px] h-[170px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '-8deg', animationDelay: '0.8s' }}
      > <img className="w-full h-full object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success2.png" alt=""/></div>
      <div
        className="absolute overflow-hidden opacity-0 -bottom-24 right-80 w-[174px] h-[174px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '8deg', animationDelay: '1s' }}
      >
        <img className="w-full h-full object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success4.png" alt=""/>
      </div>
    </div>
  );
};

export default PaymentSuccessView;
