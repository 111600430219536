import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreditCard, Camera, ZoomIn } from 'lucide-react';
import { toast } from 'react-toastify';
import axios from 'axios';
import useCredits from '../hooks/useCredits';
import { useAuth } from '@clerk/clerk-react';

const PaymentSuccessView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchCredits } = useCredits();
  const { getToken } = useAuth();
  const [isVerifying, setIsVerifying] = useState(true);
  const [verificationComplete, setVerificationComplete] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get('amount');
  const type = searchParams.get('type');
  const credits = searchParams.get('credits');

  const formatAmount = (amount) => {
    const amountNum = Number(amount);
    if (amountNum === 1020 || amountNum === 4250) {
      return `₹${amountNum}`;
    }
    return `$${amountNum}`;
  };

  useEffect(() => {
    let isSubscribed = true;

    const verifyAndUpdateCredits = async () => {
      const xIntentId = localStorage.getItem('xpay_intent_id') || searchParams.get('xpay_intent_id');
      
      if (!xIntentId) {
        console.log('No xPay intent ID found, checking URL parameters');
        if (amount && credits) {
          if (isSubscribed) {
            await fetchCredits();
           // toast.success('Credits updated successfully!');
            setVerificationComplete(true);
          }
        } else {
          console.error('Missing required payment parameters');
          navigate('/payment-failure');
        }
        setIsVerifying(false);
        return;
      }

      try {
        console.log('Verifying payment status for:', xIntentId);
        const token = await getToken();
        
        if (!isSubscribed) return;

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/xpay-payment-status/${xIntentId}`,
          {},
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('Payment verification response:', response.data);

        if (!isSubscribed) return;

        if (response.data.status === 'success') {
          await fetchCredits();
          //toast.success('Credits updated successfully!');
          setVerificationComplete(true);
        } else {
          console.error('Payment verification failed:', response.data);
          toast.error('Failed to verify payment');
          navigate('/payment-failure');
        }
      } catch (error) {
        console.error('Error verifying payment:', error);
        if (isSubscribed) {
          toast.error('Error verifying payment');
          navigate('/payment-failure');
        }
      } finally {
        localStorage.removeItem('xpay_intent_id');
        localStorage.removeItem('xpay_amount');
        localStorage.removeItem('xpay_type');
        
        if (isSubscribed) {
          setIsVerifying(false);
        }
      }
    };

    verifyAndUpdateCredits();

    return () => {
      isSubscribed = false;
    };
  }, [getToken, navigate, fetchCredits, amount, credits]);

  const creditOptions = [
    { name: 'Adding a model', credits: 800, icon: <CreditCard size={24} /> },
    { name: 'Create a shot', credits: 7, icon: <Camera size={24} /> },
    { name: 'Upscale a shot', credits: 30, icon: <ZoomIn size={24} /> },
  ];

  if (isVerifying) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
          <p className="mt-4">Verifying payment...</p>
        </div>
      </div>
    );
  }

  if (!verificationComplete && !isVerifying) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
          <p className="text-xl text-red-600">Payment verification failed</p>
          <button 
            className="mt-4 w-full bg-black text-white py-3 rounded-lg"
            onClick={() => navigate('/xpay')}
          >
            Return to payment page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
        <h1 className="text-3xl font-bold mb-4 text-green-600">
          🐠 Just keep Fooshin'! 🐠
        </h1>
        
        <p className="text-xl font-semibold mb-6">
          Payment Successful!
        </p>

        <p className="mb-4">
          Your {type === 'oneTime' ? 'one-time payment' : 'subscription'} of {formatAmount(amount)} has been processed successfully.
        </p>
        
        <p className="text-2xl font-semibold mb-6">
          {credits} credits added.
        </p>
        
        <div className="grid grid-cols-3 gap-4 mb-6">
          {creditOptions.map((option, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded">
              <div className="text-3xl mb-2">{option.icon}</div>
              <p className="font-semibold">{option.name}</p>
              <p className="text-lg font-bold">{option.credits} credits</p>
            </div>
          ))}
        </div>
        
        <p className="text-sm italic mb-6">
          PS: Your credits never expire!
        </p>
        
        <button 
          className="w-full bg-black text-white py-3 rounded-lg mb-4"
          onClick={() => navigate('/')}
        >
          Continue making epic shots
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessView;