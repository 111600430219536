import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import GenerationGroup from '../components/GenerationGroup';
import Loader from '../assets/icons/loader.svg'

const ShimmerEffect = () => (
    <div className="animate-pulse bg-gray-300 rounded-lg w-full h-64"></div>
);

const GeneratedImages = ({
    generatedGroups,
    loadMore,
    hasMore,
    setSelectedImage,
    setUpscaleQueue,
    setCompletedUpscale,
    isLoading,
    showPrompt = false,
}) => {
    return (
        <InfiniteScroll
            dataLength={generatedGroups.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
                <div className="grid grid-cols-4 gap-4 mt-4">
                    {[...Array(4)].map((_, index) => (
                        <ShimmerEffect key={index} />
                    ))}
                </div>
            }
        >
            {
                generatedGroups.map((group, index) =>
                    <GenerationGroup
                        key={index}
                        showPrompt={showPrompt}
                        setUpscaleQueue={setUpscaleQueue}
                        aspectRatio={group.aspect_ratio}
                        generationId={group.generation_id}
                        images={group.images}
                        prompt={group.prompt}
                        setSelectedImage={setSelectedImage}
                        setCompletedUpscale={setCompletedUpscale}
                    />
                )
            }
            {
                isLoading &&
                <div className="w-full text-xl font-bold flex h-20 justify-center items-center mt-2 mb-10">
                    <img className="h-20" src={Loader} alt="loader" />
                </div>
            }
        </InfiniteScroll>
    );
};

export default GeneratedImages;