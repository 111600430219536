import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';
import PaymentView from './views/PaymentView';
import PaymentSuccessView from './views/PaymentSuccessView';
import PaymentFailureView from './views/PaymentFailureView';
import OAuthCallback from './components/OAuthCallback';
import StudioView from './views/StudioView';
import GalleryView from './views/GalleryView';
import TrainNewModel from './views/TrainNewModel';
import ModalTrainingView from './views/ModalTrainingView';
import ModelImageUploadView from './views/ModelImageUploadView';
import ImageCaptionView from './views/ImageCaptionView';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import IntercomComponent from './components/IntercomComponent';
import IntlSub from './views/IntlSub';
import SubscriptionSuccessView from './views/SubscriptionSuccessView';
import SubscriptionFailureView from './views/SubscriptionFailureView';
import PaymentSuccessViewPaypal from './views/PaymentSuccessViewPaypal';
import UnifiedOneTimePayments from './views/UnifiedOneTimePayments';
import XPayView from './views/XPayView';
import UnifiedPaymentView from './views/UnifiedPaymentView';
import SignInUpView from './views/SignInUpView';
import UpscaleTracker from './components/UpscaleTracker';
import TrainNewSuccessView from './TrainNewSuccessView';
import MainEyeWearTrainigView from './views/EyeWearTrainingFlow/MainEyeWearTrainigFlow'

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const queryClient = new QueryClient();

function AppContent() {
  const location = useLocation();
  const hideHeaderPaths = ['/sign-in', '/sign-up'];
  const showHeader = !hideHeaderPaths.includes(location.pathname);
  const [upscaleQueue, setUpscaleQueue] = useState([]);
  const [completedUpscale, setCompletedUpscale] = useState([]);

  return (
    <div className="h-screen bg-white flex flex-col w-screen">
      {showHeader && <Header />}
      <Routes>
        <Route path="/sign-in" element={<SignInUpView isSignIn={true} />} />
        <Route path="/sign-up" element={<SignInUpView isSignIn={false} />} />
        <Route path="/sign-in/sso-callback" element={<OAuthCallback />} />
        <Route path="/" element={
          <ProtectedRoute>
            <StudioView
              setCompletedUpscale={setCompletedUpscale}
              setUpscaleQueue={setUpscaleQueue}
            />
          </ProtectedRoute>
        } />
        <Route path="/studio" element={
          <ProtectedRoute>
            <StudioView
              setCompletedUpscale={setCompletedUpscale}
              setUpscaleQueue={setUpscaleQueue}
            />
          </ProtectedRoute>
        } />
        <Route path="/gallery" element={
          <ProtectedRoute>
            <GalleryView
              setCompletedUpscale={setCompletedUpscale}
              setUpscaleQueue={setUpscaleQueue}
            />
          </ProtectedRoute>
        } />
        <Route path="/train" element={
          <ProtectedRoute>
            <TrainNewModel />
          </ProtectedRoute>
        } />
        <Route path="/models" element={
          <ProtectedRoute>
            <ModalTrainingView />
          </ProtectedRoute>
        } />
        <Route path="/model-images" element={
          <ProtectedRoute>
            <ModelImageUploadView />
          </ProtectedRoute>
        } />
        <Route path="/models/eyewear" element={
          <ProtectedRoute>
            <MainEyeWearTrainigView />
          </ProtectedRoute>
        } />
        <Route path="/intl-sub" element={
          <ProtectedRoute>
            <IntlSub />
          </ProtectedRoute>
        } />
        <Route path="/model-image-captions/:model_id" element={
          <ProtectedRoute>
            <ImageCaptionView />
          </ProtectedRoute>
        } />
        <Route path="/train-new" element={
    <ProtectedRoute>
        <TrainNewSuccessView />
          </ProtectedRoute>
        } />
        <Route path="/payments" element={
          <ProtectedRoute>
            <PaymentView />
          </ProtectedRoute>
        } />
        <Route path="/payment-success" element={
          <ProtectedRoute>
            <PaymentSuccessView />
          </ProtectedRoute>
        } />
        <Route path="/payment-success-paypal" element={
          <ProtectedRoute>
            <PaymentSuccessViewPaypal />
          </ProtectedRoute>
        } />
        <Route path="/billing" element={
          <ProtectedRoute>
            <UnifiedPaymentView />
          </ProtectedRoute>
        } />
        <Route path="/payment-failure" element={
          <ProtectedRoute>
            <PaymentFailureView />
          </ProtectedRoute>
        } />
        <Route path="/payment-pending" element={
          <ProtectedRoute>
            <PaymentFailureView />
          </ProtectedRoute>
        } />
        <Route path="/subscription-success" element={
          <ProtectedRoute>
            <SubscriptionSuccessView />
          </ProtectedRoute>
        } />
        <Route path="/subscription-failure" element={
          <ProtectedRoute>
            <SubscriptionFailureView />
          </ProtectedRoute>
        } />
                <Route path="/xpay" element={
          <ProtectedRoute>
            <XPayView />
          </ProtectedRoute>
        } />

      </Routes>
      <UpscaleTracker
        upscaleQueue={upscaleQueue}
        completedUpscale={completedUpscale}
      />
      <ToastContainer />
      <IntercomComponent />
    </div>
  );
}
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ClerkProvider publishableKey={clerkPubKey}>
        <Router>
          <AppContent />
        </Router>
      </ClerkProvider>
    </QueryClientProvider>
  );
}

export default App;