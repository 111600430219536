import React, { useState, useEffect, useCallback } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Star, CreditCard, Zap, Mail, Phone } from 'lucide-react';
import axios from 'axios';
import useCredits from '../hooks/useCredits';
import CreditAddModelBag from '../assets/icons/AddModelCreditSystem@3x.jpg';
import CreatShotCreditSystem from '../assets/icons/CreateShotCreditSystem@3x.png';
import UpscaleShotCreditSystem from '../assets/icons/UpscaleShotCreditSystem@3x.png';
import Loader from '../assets/icons/loader.svg';
const PaymentView = () => {
  const [topUpAmount, setTopUpAmount] = useState('');
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingPlans, setProcessingPlans] = useState({});
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [currentPlanIndex, setCurrentPlanIndex] = useState(-1);
  const [showManageModal, setShowManageModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { fetchCredits } = useCredits();
  let razorpayKey;

  if (process.env.REACT_APP_API_URL === 'http://localhost:8000') {
    razorpayKey = 'rzp_test_T21hAYkJapF4ww';
    //console.log("razorpayKey", razorpayKey);
  }
  if (process.env.REACT_APP_API_URL === 'https://staging.foosh.ai') {
    razorpayKey = 'rzp_test_T21hAYkJapF4ww';
    //console.log("razorpayKey", razorpayKey);
  }
  if (process.env.REACT_APP_API_URL === 'https://prod.foosh.ai') {
    razorpayKey = 'rzp_live_VB3jVhl6kBcJed';
    // console.log("razorpayKey", razorpayKey);
  }

  const creditOptions = [
    { name: 'Adding a model',name2:'Training a model', credits: 800, icon: CreditAddModelBag },
    { name: 'Create a shot',name2:'Generate an image', credits: 7, icon: CreatShotCreditSystem },
    { name: 'Upscale a shot',name2:'Upscale an image', credits: 30, icon: UpscaleShotCreditSystem },
  ];

  // Define plan IDs based on environment
  const getPlanIds = () => {
    if (
      process.env.REACT_APP_API_URL === 'http://localhost:8000' ||
      process.env.REACT_APP_API_URL === 'https://staging.foosh.ai'
    ) {
      return {
        starter: 'plan_PMEwtUkKIURTua',
        creator: 'plan_PMFhKFLkg87PLj',
      };
    } else if (process.env.REACT_APP_API_URL === 'https://prod.foosh.ai') {
      return {
        starter: 'plan_PMDpf0c9Dov38C',
        creator: 'plan_PMDq79Bpegqa93',
      };
    }
    // Fallback to test IDs
    return {
      starter: 'plan_PMEwtUkKIURTua',
      creator: 'plan_PMFhKFLkg87PLj',
    };
  };

  const planIds = getPlanIds();

  const monthlyPlans = [
    {
      id: planIds.starter,
      name: 'Starter',
      price: 1020,
      credits: 1000,
      icon: '📷',
      features: ['1000 credits monthly'],
    },
    {
      id: planIds.creator,
      name: 'Creator',
      price: 4250,
      credits: 5000,
      icon: '🎨',
      features: ['5000 credits monthly'],
    },
  ];

  const fetchSubscriptionHistory = async () => {
    try {
      const token = await getToken({ template: 'token' });
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/subscriptions-history`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success && response.data.subscription) {
        console.log('Subscription history:', response.data.subscription);
        setSubscriptionHistory(response.data.subscription);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching subscription history:', error);
      toast.error(
        'Failed to load subscription details. Please refresh the page.'
      );
      setLoading(false);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionHistory();
    setLoading(true);
  }, [getToken]);

  useEffect(() => {
    if (subscriptionHistory) {
      const index = Object.keys(subscriptionHistory)
        .filter(key => key.startsWith('sub'))
        .findIndex(key => subscriptionHistory[key]);
      setCurrentPlanIndex(index);
    }
  }, [subscriptionHistory]);

  const isUpgradePlan = useCallback(
    planIndex => {
      return currentPlanIndex !== -1 && planIndex > currentPlanIndex;
    },
    [currentPlanIndex]
  );

  const handleTopUpAmountChange = e => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setTopUpAmount(value);
    }
  };

  const calculateCredits = amount => {
    const parsedAmount = parseInt(amount);
    return isNaN(parsedAmount) ? 0 : parsedAmount * 100;
  };

  const handleContactUs = () => {
    const calendlyUrl = 'https://calendly.com/hi-foosh/30min';
    window.open(calendlyUrl, '_blank');
  };

  const handleTopUp = async () => {
    const amount = parseFloat(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Minimum top-up amount is $10');
      return;
    }
    await handlePayment('oneTime', calculateCredits(amount), amount);
  };

  const handleSubscribe = async plan => {
    await handlePayment('subscription', plan.credits, plan.price, plan.id);
  };

  const handleUpgrade = async plan => {
    await handlePayment('upgrade', plan.credits, plan.price, plan.id);
  };

  const handleSubscriptionAction = (plan, isUpgrade) => {
    if (hasActiveSubscription && isUpgrade) {
      handleUpgrade(plan);
    } else {
      handleSubscribe(plan);
    }
  };

  const handleCancelSubscription = async subscriptionId => {
    if (
      !window.confirm(
        'Are you sure you want to cancel your subscription? This action cannot be undone.'
      )
    ) {
      return;
    }

    const loadingToastId = toast.loading('Cancelling subscription...');

    try {
      const token = await getToken({ template: 'token' });
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cancel-razorpay-subscription`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast.dismiss(loadingToastId);

      if (response.data.success) {
        toast.success('Subscription cancelled successfully');
        await fetchSubscriptionHistory();
        await fetchCredits();
      } else {
        throw new Error(
          response.data.message || 'Failed to cancel subscription'
        );
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error('Error cancelling subscription:', error);
      toast.error(
        error.response?.data?.detail || 'Failed to cancel subscription'
      );
    }
  };

  const getPaymentDescription = (paymentType, credits, planId) => {
    switch (paymentType) {
      case 'oneTime':
        return `Top up ${credits} credits`;
      case 'upgrade':
        return `Upgrade to ${planId}`;
      case 'subscription':
        return `Subscribe to ${planId}`;
      default:
        return '';
    }
  };

  const resetProcessingState = (paymentType, planId) => {
    if (paymentType === 'oneTime') {
      setProcessingPayment(false);
    } else {
      setProcessingPlans(prev => ({ ...prev, [planId]: false }));
    }
  };
  const handlePayment = async (paymentType, credits, amount, planId = null) => {
    // Set initial processing state
    if (paymentType === 'oneTime') {
      setProcessingPayment(true);
    } else {
      setProcessingPlans(prev => ({ ...prev, [planId]: true }));
    }

    try {
      if (!razorpayKey) {
        throw new Error('Razorpay Key ID is not set');
      }

      const token = await getToken({ template: 'token' });
      let response;
      let orderData = null;

      // Create order or subscription based on payment type
      if (paymentType === 'oneTime') {
        const amountInPaise = Math.round(amount * 100) * 85; // Convert to INR paise
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-razorpay-order`,
          { amount: amountInPaise },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        orderData = response.data;
      } else {
        const endpoint =
          paymentType === 'upgrade'
            ? '/upgrade-razorpay-subscription'
            : '/create-razorpay-subscription';

        response = await axios.post(
          `${process.env.REACT_APP_API_URL}${endpoint}`,
          { plan_id: planId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      // Configure Razorpay options
      const options = {
        key: razorpayKey,
        currency: 'INR',
        name: 'Foosh.ai',
        description: getPaymentDescription(paymentType, credits, planId),
        prefill: {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress,
        },
        modal: {
          ondismiss: () => {
            toast.info('Payment cancelled');
            resetProcessingState(paymentType, planId);
          },
        },
        notes: {
          payment_type: paymentType,
          credits: credits.toString(),
          user_id: user.id,
        },
      };

      // Add payment type specific options
      if (paymentType === 'oneTime') {
        options.amount = orderData.amount;
        options.order_id = orderData.id;
      } else {
        options.subscription_id = response.data.subscription_id;
        options.notes.plan_id = planId;
        if (response.data.upgraded_from) {
          options.notes.upgraded_from =
            response.data.upgraded_from.subscription_id;
        }
      }

      // Payment verification handler
      options.handler = async function (razorpayResponse) {
        const loadingToastId = toast.loading('Processing payment...');

        try {
          const verifyToken = await getToken();
          const paymentData = {
            payment_type: paymentType,
            razorpay_payment_id: razorpayResponse.razorpay_payment_id,
            razorpay_signature: razorpayResponse.razorpay_signature,
            ...(paymentType === 'oneTime'
              ? {
                  razorpay_order_id: orderData.id,
                  razorpay_subscription_id: null,
                  plan_id: null,
                  upgraded_from: null,
                }
              : {
                  razorpay_order_id: null,
                  razorpay_subscription_id:
                    razorpayResponse.razorpay_subscription_id,
                  plan_id: planId,
                  upgraded_from: response.data.upgraded_from,
                }),
          };

          const verifyResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/verify-razorpay-payment`,
            paymentData,
            {
              headers: {
                Authorization: `Bearer ${verifyToken}`,
                'Content-Type': 'application/json',
              },
            }
          );

          toast.dismiss(loadingToastId);

          // Handle the response based on payment type and status
          const status = verifyResponse.data.status;
          const success = verifyResponse.data.success;

          if (!success) {
            throw new Error(
              verifyResponse.data.message || 'Payment verification failed'
            );
          }

          // Handle subscription/upgrade payments
          if (['subscription', 'upgrade'].includes(paymentType)) {
            // Consider both 'authenticated' and 'active' as successful states
            if (['authenticated', 'active'].includes(status)) {
              const upgradeContext = response.data.upgraded_from
                ? `&upgraded=true&previousPlan=${response.data.upgraded_from.plan_id}`
                : '';
          
              await Promise.all([fetchSubscriptionHistory(), fetchCredits()]);
          
              toast.success('Subscription activated successfully');
              navigate(
                `/subscription-success?plan_id=${planId}&subscription_id=${response.data.subscription_id}&amount=${amount}&type=${paymentType}&credits=${credits}${upgradeContext}&mode=rzp`
              );
              return;
            }
          }

          // Handle one-time payments
          if (paymentType === 'oneTime') {
            if (status === 'captured') {
              await fetchCredits();
              toast.success('Payment successful');
              navigate(
                `/payment-success?amount=${amount}&type=${paymentType}&credits=${credits}`
              );
              return;
            }
          }

          // Handle processing status
          if (status === 'processing') {
            toast.info('Payment is being processed');
            navigate(
              `/payment-pending?amount=${amount}&type=${paymentType}&status=${status}`
            );
            return;
          }

          // Handle unexpected status
          console.warn('Unexpected payment status:', status);
          toast.warning('Payment status is pending verification');
          navigate(
            `/payment-pending?amount=${amount}&type=${paymentType}&status=${status}`
          );
        } catch (error) {
          console.error('Payment verification error:', error);
          toast.dismiss(loadingToastId);

          const errorMessage =
            error.response?.data?.detail ||
            error.response?.data?.message ||
            error.message;

          toast.error(`Payment verification failed: ${errorMessage}`);
          navigate(
            `/payment-failure?amount=${amount}&type=${paymentType}&reason=${encodeURIComponent(
              errorMessage
            )}`
          );
        } finally {
          resetProcessingState(paymentType, planId);
        }
      };

      // Initialize and open Razorpay
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error creating payment:', error);
      const errorMessage = error.response?.data?.detail || error.message;
      toast.error(`Error creating payment: ${errorMessage}`);
      resetProcessingState(paymentType, planId);
    }
  };
  const formatDate = dateString => {
    const date = new Date(dateString); // Convert the ISO string to a Date object
    date.setMonth(date.getMonth() + 1); // Add one month to the date
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    return `${day}/${month}/${year}`;
  };
  const hasActiveSubscription =
    subscriptionHistory &&
    (subscriptionHistory.sub1 ||
      subscriptionHistory.sub2 ||
      subscriptionHistory.sub3);

  if (loading)
    return (
      <div className="w-full text-xl font-bold flex h-full  justify-center items-center ">
        <img className="h-20" src={Loader} alt="loader" />
      </div>
    );

  return (
    <div className="max-w-6xl mx-auto p-4">
      {hasActiveSubscription && (
        <div className="mb-12 mt-4 ">
          <div className=" flex items-center gap-4   justify-between rounded-lg">
            {/* <div className="w-1/2 flex"> */}
            <div className="  flex  flex-col ">
              <div className="text-lg font-semibold  ">
                {' '}
                Top up more credits
              </div>
              <div className="text-[12px] font-light ">
                Your top up credits never expire
              </div>
            </div>
            <div className="flex flex-col max-w-[20%] relative ">
              <div className="flex w-full font-light items-center   border-[1px] p-2 rounded-lg ">
                <span className="text-2xl mr-2">$</span>
                <input
                  type="text"
                  value={topUpAmount}
                  onChange={handleTopUpAmountChange}
                  className="flex  w-full text-2xl focus:outline-none focus:border-black"
                  placeholder="100"
                />
                {/* <span className="ml-4 text-gray-600">
                  {calculateCredits(topUpAmount)} credits
                </span> */}
              </div>
              {topUpAmount && parseInt(topUpAmount) < 10 && (
                <p
                  className={`text-red-500 text-sm
                     'flex absolute -bottom-10 left-2'
                    `}
                >
                  Minimum amount should be $10
                </p>
              )}
            </div>
            {/* </div> */}

            <div
              onClick={handleTopUp}
              disabled={processingPayment || parseInt(topUpAmount) < 10}
              className={`py-[.7rem] cursor-pointer flex grow justify-center items-center rounded-xl text-lg font-semibold  ${
                parseInt(topUpAmount) >= 10 && !processingPayment
                  ? 'bg-black text-white hover:bg-gray-800'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {processingPayment
                ? 'Processing...'
                : `Add ${calculateCredits(topUpAmount)} credits`}
            </div>
          </div>
        </div>
      )}

      <div>
        <h2 className="text-lg font-bold mb-2">Monthly Plans </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          {monthlyPlans.map((plan, index) => {
            const planKey = `sub${index + 1}`;
            const isSubscribed =
              subscriptionHistory && subscriptionHistory[planKey];
            const isUpgrade = isUpgradePlan(index);

            return (
              <div
                key={plan.id}
                className={` ${
                  isSubscribed ? 'bg-[#CCFFC7] ' : 'bg-[#00000007]'
                } p-4 rounded-lg `}
              >
                <div className="text-start mb-4">
                  <h3 className="text-sm ">
                    {plan.name} {plan.icon}{' '}
                  </h3>
                  <div className="mb-3">
                    ₹{plan.price}
                    <span className=""> month</span>
                  </div>
                  <div className="text-xl font-bold mb-4">
                    {isSubscribed &&
                      ` ${subscriptionHistory.subscription_credits} / `}
                    {plan.credits} credits
                  </div>
                </div>

                {/* <ul className="mb-6">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <Star className="w-5 h-5 mr-2 text-yellow-500" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul> */}

                {isSubscribed ? (
                  <div>
                    <button
                      onClick={() => setShowManageModal(true)}
                      className="w-full text-lg leading-[1] py-[.4rem] rounded-lg font-semibold  mb-2 bg-white text-black hover:bg-black hover:text-white"
                    >
                      Manage
                      <br />
                      <span className="text-[.75rem] font-light leading-[.75]">
                        Renews on {formatDate(subscriptionHistory.modified_at)}
                      </span>
                    </button>
                    {/* <button
                      onClick={() => setShowManageModal(true)}
                      className="w-full py-2 rounded-lg font-semibold border-2 border-gray-300 text-gray-700 hover:bg-gray-50"
                    >
                      Manage Plan
                    </button> */}
                  </div>
                ) : (
                  <button
                    onClick={() =>
                      handleSubscriptionAction(plan, isUpgradePlan(index))
                    }
                    disabled={processingPlans[plan.id]}
                    className={`w-full py-3 rounded-lg font-semibold ${
                      processingPlans[plan.id]
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        : isUpgrade
                        ? 'bg-white text-black hover:bg-black hover:text-white'
                        : hasActiveSubscription
                        ? 'bg-white text-black hover:bg-black hover:text-white'
                        : 'bg-white text-black hover:bg-black hover:text-white'
                    }`}
                  >
                    {processingPlans[plan.id]
                      ? 'Processing...'
                      : isUpgrade
                      ? 'Upgrade Plan'
                      : hasActiveSubscription
                      ? 'Change Plan'
                      : 'Subscribe Now'}
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className=" border-[1px] text- p-4 mt-4 rounded-lg">
        <div className="text-start flex">
          <div className="w-full">
            <h4 className="mb-1 text-sm">ENTERPRISE</h4>
            <div className="flex w-full justify-between ">
              <div className="font-bold text-xl -leading-6">
                Unlock tailored pricing and <br /> unlimited shots for your
                teams.
              </div>
              <div
                className="cursor-pointer flex bg-black text-white px-12 items-center  rounded-lg font-semibold hover:bg-gray-800 transition-colors"
                onClick={handleContactUs}
              >
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-6 ">
        <div className="font-bold text-lg">How credits work</div>
        <div className="grid grid-cols-3 gap-4 mb-8">
          {creditOptions.map((option, index) => (
            <div key={index} className="flex items-center bg-white p-4 ">
              <img className=" w-16" src={option.icon} alt=""></img>
              <div className=" p-2">
                <p className="text-gray-600 text-sm">{hasActiveSubscription?option.name2:option.name}</p>
                <p className=" font-semibold text-xl">
                  {option.credits} credits
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showManageModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
            <h3 className="text-xl font-semibold mb-4">
              Manage Your Subscription
            </h3>
            <p className="text-gray-600 mb-4">
            For invoices or cancelling a subscription please reach out to us at{' '}
              <a
                href="mailto:support@foosh.ai"
                className="text-blue-600 hover:underline"
              >
                support@foosh.ai
              </a>
            </p>
            <button
              className="bg-black text-white px-4 py-2 rounded w-full hover:bg-gray-800"
              onClick={() => setShowManageModal(false)}
            >
              Okay, got it
            </button>
          </div>
        </div>
      )}

      <div className=" flex items-end text-center justify-center">
        <div className=" flex grow h-[.25px] border-[.25px] mb-2 mr-2 "></div>
        <div className="text-center font-light text-[12px] text-gray-600 mt-8">
          Prices are subject to change at any time. <br /> Get priority support
          on support@foosh.ai
        </div>
        <div className="bg-green-200 h-[.25px] flex grow border-[.25px] mb-2 ml-2"></div>
      </div>
    </div>
  );
};

export default PaymentView;
