import React, { useState, useRef, useEffect } from 'react';
import { Heart, Copy } from 'lucide-react';
import { toast } from 'react-toastify';
import { ReactComponent as Upscale } from '../assets/icons/upscale.svg';
import { ReactComponent as UpscaleDone } from '../assets/icons/upscale_done.svg';
import { useUser, useAuth } from '@clerk/clerk-react';
import IconButton from './IconButton';
import LazyImage from './LazyImage';
import { useNavigate } from 'react-router-dom';
import Reimagine from '../assets/icons/Reimagine Button.svg'
import upscaleProgLottie from '../assets/icons/upscale_lottie.gif';

const ImageActions = ({
    generationId,
    img,
    onUpscale,
    onSaveToGallery,
    isSaved,
}) => {
    const isImageSaved = isSaved || img.saved_to_gallery;
    const isUpscaleRequested = img.upscale_status === 'REQUESTED';
    const isUpscaled = img.upscale_status === 'UPSCALED';
    const navigate = useNavigate();
    const UpscaleProgress = () => (
        <img src={upscaleProgLottie} alt="Upscale in progress" width="20" height="20" />
    );
    let upscaleIcon,
        upscaleTitle,
        upscaleIconColor,
        isUpscaleDisabled,
        showUpscaleBorder;

    if (isUpscaleRequested) {
        upscaleIcon = UpscaleProgress;
        upscaleTitle = 'Upscale in progress';
        upscaleIconColor = '#6bcc63';
        isUpscaleDisabled = true;
        showUpscaleBorder = false;
    } else if (isUpscaled) {
        upscaleIcon = UpscaleDone;
        upscaleTitle = 'View AI Upscale';
        upscaleIconColor = '#6bcc63';
        isUpscaleDisabled = true;
        showUpscaleBorder = false;
    } else {
        upscaleIcon = Upscale;
        upscaleTitle = 'AI Upscale';
        upscaleIconColor = 'white';
        isUpscaleDisabled = false;
        showUpscaleBorder = true;
    }

    return (
        <div className="flex h-full gap-2">
            <div className={`p-2 relative rounded-full cursor-pointer  bg-black   hover:bg-opacity-75 transition-all duration-200 ease-in-out group flex  justify-center items-center aspect-1`} onClick={() => { navigate(`/studio?imageId=${img.image_id}`) }}>
                <img src={Reimagine} alt="Reimagine" />
                <span className="absolute bottom-full -right-[50%] mb-2 hidden group-hover:block bg-black text-white text-xs py-1 px-2 rounded-md">
                    Reimagine
                </span>
            </div>
            <IconButton
                icon={upscaleIcon}
                onClick={() => onUpscale(img, generationId)}
                title={upscaleTitle}
                color={upscaleIconColor}
                showBorder={showUpscaleBorder}
            />
            <IconButton
                icon={Heart}
                onClick={() => onSaveToGallery(img, generationId)}
                title={isImageSaved ? 'Added to Gallery' : 'Save to Gallery'}
                disabled={isImageSaved}
                color={isImageSaved ? 'red' : 'white'}
                fill={isImageSaved ? 'red' : 'none'}
                showBorder={!isImageSaved}
            />
        </div>
    );
};

const GeneratedImage = ({
    thumbnailUrl,
    image,
    setSelectedImage,
    setUpscaleQueue,
    setCompletedUpscale
}) => {
    const [savedImages, setSavedImages] = useState({});
    const [img, setImg] = useState(image);
    const { user } = useUser();
    const { getToken } = useAuth();
    const [isHovered, setIsHovered] = useState(false);


    const navigate = useNavigate();
    const timeoutRefs = useRef(new Map());
    const intervalRefs = useRef(new Map());
    const previousUpscaleStatusRef = useRef(null);
    const isInitialRenderRef = useRef(true);

    const startUpscalePoller = (imageId, firstPollAfter) => {
        setUpscaleQueue(prev =>
            prev.includes(imageId) ? prev : [...prev, imageId]
        );

        const cleanupImage = (imageId) => {
            if (timeoutRefs.current.has(imageId)) {
                clearTimeout(timeoutRefs.current.get(imageId));
                timeoutRefs.current.delete(imageId);
            }
            if (intervalRefs.current.has(imageId)) {
                clearInterval(intervalRefs.current.get(imageId));
                intervalRefs.current.delete(imageId);
            }
        };

        const handleCompletedUpscale = (imageId) => {
            setUpscaleQueue(prev => {
                const newQueue = prev.filter(id => id !== imageId);
                // Only update completedUpscale if the img was actually in the queue
                if (prev.length !== newQueue.length) {
                    setCompletedUpscale(prevCompleted =>
                        prevCompleted.includes(imageId) ? prevCompleted : [...prevCompleted, imageId]
                    );
                }
                return newQueue;
            });
        }

        const checkImageStatus = async (imageId) => {
            try {
                const token = await getToken({ template: 'token' });
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/image-details?image_id=${imageId}`,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                return data.details.upscale_status === "UPSCALED";
            } catch (error) {
                console.error(`Error checking status for ${imageId}:`, error);
                return false;
            }
        };

        const verifyStatus = async () => {
            try {
                const isComplete = await checkImageStatus(imageId);
                console.log('Status check result:', isComplete, 'for img:', imageId);

                if (isComplete) {
                    // Clean up only this img's timers
                    setImg((prev) => ({ ...prev, upscale_status: "UPSCALED" }))
                    cleanupImage(imageId);
                    handleCompletedUpscale(imageId);
                }
            } catch (error) {
                console.error(`Error in verification process for ${imageId}:`, error);
            }
        }

        const timeoutId = setTimeout(() => {
            verifyStatus();
            // Setup 15-second interval after initial check
            const intervalId = setInterval(verifyStatus, 15000);
            intervalRefs.current.set(imageId, intervalId);
        }, firstPollAfter);
        timeoutRefs.current.set(imageId, timeoutId);
    }

    const handleUpscale = async (img, generationId) => {
        if (!user) return;
        if (img.upscale_status !== "NOTREQUESTED") return;

        try {
            const token = await getToken({ template: 'token' });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/upscale`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    generation_id: generationId,
                    image_id: img.image_id,
                }),
            });
            if (response.status === 475) {
                const toastId = toast.error(
                    <div className="flex justify-between items-center w-full">
                        <span className="flex-grow">Insufficient credits for this task </span>
                        <button
                            onClick={() => {
                                toast.dismiss(toastId);
                                navigate('/billing');
                            }}
                            className="ml-4 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded"
                        >
                            Add credits
                        </button>
                    </div>,
                    {
                        position: "bottom-center",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        className: 'w-full max-w-md',
                    }
                );
            } else if (response.ok) {
                setImg((prev) => ({ ...prev, upscale_status: "REQUESTED" }))

            } else {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to request upscale');
            }
        } catch (error) {
            console.error('Error requesting upscale:', error);
            toast.error(`Failed to request upscale : ${error.message}`, {
                position: 'bottom-center',
            });
        }
    };

    const handleSaveToGallery = async (img, generationId) => {
        if (!user) return;

        try {
            const token = await getToken({ template: 'token' });
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/save-to-gallery`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        generation_id: generationId,
                        image_id: img.image_id,
                        like: true
                    }),
                }
            );

            if (response.ok) {
                toast.success('Image saved to gallery successfully!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setSavedImages(prev => ({ ...prev, [img.image_id]: true }));
            } else {
                toast.error('Failed to save img to gallery', {
                    position: 'bottom-center',
                });
            }
        } catch (error) {
            console.error('Error saving img to gallery:', error);
            toast.error('Failed to save img to gallery', {
                position: 'bottom-center',
            });
        }
    };

    // useEffect to start upscale poller when status is REQUESTED
    useEffect(() => {
        // Start poller if:
        // 1. Status is REQUESTED AND 
        // 2. Either it's the first render AND initial status was REQUESTED 
        // OR the status has changed to REQUESTED
        if (img.upscale_status === "REQUESTED") {
            if (isInitialRenderRef.current) {
                // For an image which was previously put on upscale, start the polling after 1min
                startUpscalePoller(img.image_id, 60000);
            } else if (previousUpscaleStatusRef.current !== img.upscale_status) {
                // For an image which is freshly put on upscale, start the polling after 3min
                startUpscalePoller(img.image_id, 180000);
            }
            // Update refs
            previousUpscaleStatusRef.current = img.upscale_status;
            isInitialRenderRef.current = false;
        }
    }, [img.upscale_status]);

    return (
        <div
            key={img.image_id}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <LazyImage
                src={thumbnailUrl}
                alt={`Generated img ${img.image_id}`}
                className="w-full h-auto object-cover rounded-[20px] cursor-pointer"
                onClick={() => { setSelectedImage({ image: img }); }}
            />
            <div className={`${isHovered ? "absolute" : "hidden"}  bottom-2 right-2`}>
                <ImageActions
                    generationId={img.generation_id}
                    img={img}
                    onUpscale={handleUpscale}
                    onSaveToGallery={handleSaveToGallery}
                    isSaved={savedImages[img.image_id]}
                />
            </div>
        </div>
    );
};

export default GeneratedImage;
