import React, { useState, useEffect } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Star, CreditCard, Zap, Mail, Phone } from 'lucide-react';
import axios from 'axios';
import useCredits from '../hooks/useCredits';

const XPayView = () => {
  const [topUpAmount, setTopUpAmount] = useState('');
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingSubscription, setProcessingSubscription] = useState({});
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const { user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { fetchCredits } = useCredits();

  const creditOptions = [
    { name: 'Adding a model', credits: 800, icon: <CreditCard size={24} /> },
    { name: 'Create a shot', credits: 7, icon: <Zap size={24} /> },
    { name: 'Upscale a shot', credits: 30, icon: <Zap size={24} /> },
  ];

  const subscriptionPlans = [
    {
      id: 'P-17U451794R2419509M4HLDAQ',
      name: 'Starter',
      price: 12,
      credits: 1000,
      interval: 'MONTH',
      features: ['1000 credits monthly']
    },
    {
      id: 'P-7WA05698GW114015SM4IR4WI',
      name: 'Creator',
      price: 50,
      credits: 5000,
      interval: 'MONTH',
      features: ['5,000 credits monthly']
    },
    {
      id: 'P-9XY87654ZW3210987LMNOPQR',
      name: 'Pro',
      price: 240,
      credits: 25000,
      interval: 'MONTH',
      features: ['25,000 credits monthly']
    }
  ];

  const handleContactUs = () => {
    const calendlyUrl = "https://calendly.com/hi-foosh/30min";
    window.open(calendlyUrl, "_blank");
  };

  const handleCancelSubscription = async (subscriptionId) => {
    if (!window.confirm('Are you sure you want to cancel your subscription? This action cannot be undone.')) {
      return;
    }
  
    const loadingToastId = toast.loading('Cancelling subscription...');
    
    // Store current state for rollback if needed
    const previousSubscriptionState = { ...subscriptionHistory };
  
    // Optimistically update UI
    setSubscriptionHistory(prev => ({
      ...prev,
      sub1: false,
      sub2: false,
      sub3: false,
      subscription_id: null
    }));
  
    try {
      const token = await getToken({template: 'token'});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/xpay-cancel`,
        {
          subscription_id: subscriptionId
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      toast.dismiss(loadingToastId);
  
      if (response.data.success) {
        toast.success('Subscription cancelled successfully');
        
        // Refresh subscription history in background
        try {
          const historyResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/subscriptions-history`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          
          if (historyResponse.data.success && historyResponse.data.subscription) {
            console.log('Updated subscription history:', historyResponse.data.subscription);
            setSubscriptionHistory(historyResponse.data.subscription);
          }
        } catch (historyError) {
          console.error('Error fetching updated subscription history:', historyError);
          toast.warning('Please refresh the page to ensure subscription status is up to date');
        }
      } else {
        // Rollback optimistic update on failure
        setSubscriptionHistory(previousSubscriptionState);
        throw new Error(response.data.message || 'Failed to cancel subscription');
      }
    } catch (error) {
      // Rollback optimistic update on error
      setSubscriptionHistory(previousSubscriptionState);
      
      toast.dismiss(loadingToastId);
      console.error('Error cancelling subscription:', error);
      const errorMessage = error.response?.data?.detail || error.message || 'Unknown error occurred';
      toast.error(`Failed to cancel subscription: ${errorMessage}. Please try again or contact support.`);
    }
  };

useEffect(() => {
    const fetchSubscriptionHistory = async () => {
     // const loadingToastId = toast.loading('Loading subscription details...');
      
      try {
        const token = await getToken({template: 'token'});
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subscriptions-history`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        
        //toast.dismiss(loadingToastId);
        
        if (response.data.success && response.data.subscription) {
          console.log('Subscription history:', response.data.subscription);
          setSubscriptionHistory(response.data.subscription);
        }
      } catch (error) {
       // toast.dismiss(loadingToastId);
        console.error('Error fetching subscription history:', error);
        toast.error('Failed to load subscription details. Please refresh the page.');
      }
    };

    fetchSubscriptionHistory();
}, [getToken]);
  const handleTopUpAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setTopUpAmount(value);
    }
  };

  const calculateCredits = (amount) => {
    const parsedAmount = parseInt(amount);
    return isNaN(parsedAmount) ? 0 : parsedAmount * 100;
  };

  const handleOneTimePayment = async () => {
    const amount = parseFloat(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Minimum top-up amount is $10');
      return;
    }

    setProcessingPayment(true);

    try {
      const token = await getToken({template: 'token'});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/xpay-onetime`,
        {
          amount: Math.round(amount * 100),
          currency: 'USD',
          customerDetails: {
            name: user.fullName,
            email: user.primaryEmailAddress.emailAddress
          },
          returnUrl: `${window.location.origin}/xpay?returnUrl=true`
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      localStorage.setItem('xpay_intent_id', response.data.xIntentId);
      localStorage.setItem('xpay_amount', amount.toString());
      localStorage.setItem('xpay_type', 'oneTime');

      window.location.href = response.data.fwdUrl;
    } catch (error) {
      console.error('Error creating XPay payment:', error);
      toast.error('Error creating payment. Please try again.');
    } finally {
      setProcessingPayment(false);
    }
  };


const handleSubscribe = async (plan) => {
  setProcessingSubscription({ ...processingSubscription, [plan.id]: true });

  try {
    const token = await getToken({template: 'token'});
    
    // Determine if this is an upgrade or new subscription
    const isUpgrade = hasActiveSubscription && subscriptionHistory?.subscription_id;
    const endpoint = isUpgrade ? '/upgrade-xpay-subscription' : '/xpay/subscription/create';
    
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      {
        amount: plan.price * 100,
        currency: 'USD',
        customerDetails: {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress
        },
        description: `${plan.name} Plan - ${plan.credits} credits monthly`,
        interval: 'MONTH',
        intervalCount: 1,
        cycleCount: 12,
        callbackUrl: `${window.location.origin}/subscription-success?plan_id=${plan.id}`
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    localStorage.setItem('xpay_intent_id', response.data.xIntentId);
    localStorage.setItem('xpay_amount', plan.price.toString());
    localStorage.setItem('xpay_type', plan.id);

    window.location.href = response.data.fwdUrl;
  } catch (error) {
    console.error('Error managing subscription:', error);
    toast.error(
      hasActiveSubscription 
        ? 'Error upgrading subscription. Please try again.' 
        : 'Error creating subscription. Please try again.'
    );
  } finally {
    setProcessingSubscription({ ...processingSubscription, [plan.id]: false });
  }
};
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isReturnFromXPay = urlParams.has('returnUrl');
    const xIntentId = localStorage.getItem('xpay_intent_id');
    const amount = localStorage.getItem('xpay_amount');
    const type = localStorage.getItem('xpay_type');

    if (isReturnFromXPay && xIntentId && amount && type) {
      const checkPaymentStatus = async () => {
        try {
          const token = await getToken({template: 'token'});
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/xpay-payment-status/${xIntentId}`,
            {},
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );

          const { status, credits_added } = response.data;

          localStorage.removeItem('xpay_intent_id');
          localStorage.removeItem('xpay_amount');
          localStorage.removeItem('xpay_type');

          if (status === 'success') {
            await fetchCredits();
            
            if (type === 'oneTime') {
              navigate(`/payment-success?amount=${amount}&type=${type}&credits=${credits_added}`);
            } else {
              const plan = subscriptionPlans.find(p => p.id === type);
              navigate(`/subscription-success?plan_id=${type}&credits=${plan?.credits || 0}`);
            }
          } else {
            navigate('/payment-failure');
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
          toast.error('Failed to verify payment status. Please contact support.');
          navigate('/payment-failure');
        }
      };

      checkPaymentStatus();
    }
  }, [navigate, getToken, fetchCredits, subscriptionPlans]);

  const hasActiveSubscription = subscriptionHistory && (subscriptionHistory.sub1 || subscriptionHistory.sub2 || subscriptionHistory.sub3);

  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="grid grid-cols-3 gap-4 mb-8">
        {creditOptions.map((option, index) => (
          <div key={index} className="flex items-center bg-white p-4 rounded-lg shadow-md">
            <div className="text-blue-500">{option.icon}</div>
            <div className="ml-3">
              <p className="font-semibold">{option.name}</p>
              <p className="text-gray-600">{option.credits} credits</p>
            </div>
          </div>
        ))}
      </div>

      {hasActiveSubscription && (
        <div className="mb-12">
          <h3 className="text-2xl font-bold mb-6">Top up Credits</h3>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <span className="text-2xl font-bold mr-2">$</span>
              <input
                type="text"
                value={topUpAmount}
                onChange={handleTopUpAmountChange}
                className="w-32 text-2xl font-bold border-b-2 border-gray-300 focus:outline-none focus:border-black"
                placeholder="10"
              />
              <span className="ml-4 text-gray-600">
                {calculateCredits(topUpAmount)} credits
              </span>
            </div>

            <button 
              onClick={handleOneTimePayment}
              disabled={processingPayment || parseInt(topUpAmount) < 10}
              className={`px-6 py-3 rounded text-lg font-semibold w-full ${
                parseInt(topUpAmount) >= 10 && !processingPayment
                  ? 'bg-black text-white hover:bg-gray-800'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {processingPayment ? 'Processing...' : 'Pay with XPay'}
            </button>
          </div>
        </div>
      )}

      <div>
        <h2 className="text-2xl font-bold mb-6">Subscription Plans</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {subscriptionPlans.map((plan, index) => {
            const planKey = `sub${index + 1}`;
            const isSubscribed = subscriptionHistory && subscriptionHistory[planKey] === true;
            const currentSubscriptionIndex = subscriptionPlans.findIndex((_, i) => subscriptionHistory?.[`sub${i + 1}`]);
            const isUpgrade = hasActiveSubscription && !isSubscribed && index > currentSubscriptionIndex;
            
            return (
              <div key={plan.id} className="bg-white p-6 rounded-lg shadow-md">
                <div className="text-center mb-4">
                  <h3 className="text-xl font-bold">{plan.name}</h3>
                  <div className="text-3xl font-bold my-4">
                    ${plan.price}
                    <span className="text-sm text-gray-500">/month</span>
                  </div>
                  <div className="text-lg text-gray-600 mb-4">
                    {plan.credits.toLocaleString()} credits monthly
                  </div>
                </div>

                <ul className="mb-6">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <Star className="w-5 h-5 mr-2 text-yellow-500" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>

                {isSubscribed ? (
  <button
    disabled={true}
    className="w-full py-3 rounded-lg font-semibold bg-green-500 text-white cursor-not-allowed"
  >
    Currently Subscribed
  </button>
) : (
  <button
    onClick={() => handleSubscribe(plan)}
    disabled={processingSubscription[plan.id]}
    className={`w-full py-3 rounded-lg font-semibold ${
      processingSubscription[plan.id]
        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
        : isUpgrade
        ? 'bg-purple-500 text-white hover:bg-purple-600'
        : hasActiveSubscription
        ? 'bg-blue-500 text-white hover:bg-blue-600'
        : 'bg-blue-500 text-white hover:bg-blue-600'
    }`}
  >
    {processingSubscription[plan.id]
      ? 'Processing...'
      : isUpgrade
      ? 'Upgrade Plan'
      : hasActiveSubscription
      ? 'Change Plan'
      : 'Subscribe Now'}
  </button>
)}
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-8 bg-gray-100 p-6 rounded-lg">
        <div className="text-center">
          <h4 className="font-bold text-xl mb-2">Enterprise Plan</h4>
          <p className="text-gray-600 mb-4">
            Custom solutions for teams with advanced needs. Get in touch for personalized pricing.
          </p>
          <button 
            className="bg-black text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-800 transition-colors"
            onClick={handleContactUs}
          >
            Contact Us
          </button>
        </div>
      </div>

      {showContactPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <div className="flex items-center mb-3">
              <Mail className="mr-2" size={20} />
              <a href="mailto:help@foosh.ai" className="text-blue-600 hover:underline">help@foosh.ai</a>
            </div>
            <div className="flex items-center mb-4">
              <Phone className="mr-2" size={20} />
              <span>+1 (555) 123-4567</span>
            </div>
            <button 
              className="bg-black text-white px-4 py-2 rounded w-full"
              onClick={() => setShowContactPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <p className="text-center text-sm text-gray-600 mt-8">
        Need help? Contact us at help@foosh.ai
      </p>
    </div>
  );
};

export default XPayView;