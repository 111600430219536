import React, { useState, useEffect, useCallback } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Star, CreditCard, Zap, Mail, Phone } from 'lucide-react';
import axios from 'axios';
import useCredits from '../hooks/useCredits';

const PaymentView = () => {
  const [topUpAmount, setTopUpAmount] = useState('');
  const [processingPayment, setProcessingPayment] = useState(false);
  const [processingPlans, setProcessingPlans] = useState({});
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [currentPlanIndex, setCurrentPlanIndex] = useState(-1);
  const { user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { fetchCredits } = useCredits();

  const razorpayKey = "rzp_test_T21hAYkJapF4ww";

  const creditOptions = [
    { name: 'Adding a model', credits: 800, icon: <CreditCard size={24} /> },
    { name: 'Create a shot', credits: 7, icon: <Zap size={24} /> },
    { name: 'Upscale a shot', credits: 30, icon: <Zap size={24} /> },
  ];

  const monthlyPlans = [
    { 
      id: 'plan_PMEwtUkKIURTua', 
      name: 'Starter', 
      price: 1020, 
      credits: 1000, 
      icon: '🎨', 
      features: ['1,000 credits monthly'] 
    },
    { 
      id: 'plan_PMFhKFLkg87PLj', 
      name: 'CREATOR', 
      price: 4250, 
      credits: 5000, 
      icon: '🚀', 
      features: ['5,000 credits monthly'] 
    },
  ];

  const fetchSubscriptionHistory = async () => {
    //const loadingToastId = toast.loading('Loading subscription details...');
    
    try {
      const token = await getToken({template: 'token'});
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/subscriptions-history`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
     // toast.dismiss(loadingToastId);
      
      if (response.data.success && response.data.subscription) {
        console.log('Subscription history:', response.data.subscription);
        setSubscriptionHistory(response.data.subscription);
      }
    } catch (error) {
      //toast.dismiss(loadingToastId);
      console.error('Error fetching subscription history:', error);
      toast.error('Failed to load subscription details. Please refresh the page.');
    }
  };

  useEffect(() => {
    fetchSubscriptionHistory();
  }, [getToken]);

  useEffect(() => {
    if (subscriptionHistory) {
      const index = Object.keys(subscriptionHistory)
        .filter(key => key.startsWith('sub'))
        .findIndex(key => subscriptionHistory[key]);
      setCurrentPlanIndex(index);
    }
  }, [subscriptionHistory]);

  const isUpgradePlan = useCallback((planIndex) => {
    return currentPlanIndex !== -1 && planIndex > currentPlanIndex;
  }, [currentPlanIndex]);

  const handleTopUpAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setTopUpAmount(value);
    }
  };

  const calculateCredits = (amount) => {
    const parsedAmount = parseInt(amount);
    return isNaN(parsedAmount) ? 0 : parsedAmount * 100;
  };

  const handleContactUs = () => {
    const calendlyUrl = "https://calendly.com/hi-foosh/30min";
    window.open(calendlyUrl, "_blank");
  };
  const handleTopUp = async () => {
    const amount = parseFloat(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Minimum top-up amount is ₹10');
      return;
    }
    await handlePayment('oneTime', calculateCredits(amount), amount);
  };

  const handleSubscribe = async (plan) => {
    await handlePayment('subscription', plan.credits, plan.price, plan.id);
  };
  const handleUpgrade = async (plan) => {
    if (!window.confirm(`Are you sure you want to upgrade to the ${plan.name} plan?`)) {
      return;
    }
  
    setProcessingPlans(prev => ({ ...prev, [plan.id]: true }));
  
    try {
      const token = await getToken({template: 'token'});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/upgrade-razorpay-subscription`,
        { plan_id: plan.id },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const options = {
        key: razorpayKey,
        subscription_id: response.data.subscription_id,
        name: "FOOSH.ai",
        description: `Upgrade to ${plan.name} Plan`,
        handler: async function(razorpayResponse) {
          try {
            const verifyToken = await getToken();
            const paymentData = {
              razorpay_payment_id: razorpayResponse.razorpay_payment_id,
              razorpay_signature: razorpayResponse.razorpay_signature,
              razorpay_subscription_id: razorpayResponse.razorpay_subscription_id,
              payment_type: 'subscription'
            };
            
            const verifyResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/verify-razorpay-payment`,
              paymentData,
              {
                headers: {
                  'Authorization': `Bearer ${verifyToken}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            
            if (verifyResponse.data.success) {
              if (['active', 'authenticated'].includes(verifyResponse.data.status)) {
                navigate(`/payment-success?amount=${plan.price}&type=upgrade&credits=${plan.credits}`);
                await fetchSubscriptionHistory();
                await fetchCredits();
              } else {
                navigate(`/payment-pending?amount=${plan.price}&type=upgrade&status=${verifyResponse.data.status}`);
              }
            } else {
              throw new Error(verifyResponse.data.message || 'Payment verification failed');
            }
          } catch (error) {
            console.error('Error verifying upgrade payment:', error);
            const errorMessage = error.response?.data?.detail || error.response?.data?.message || error.message;
            navigate(`/payment-failure?type=upgrade&reason=${encodeURIComponent(errorMessage)}`);
          } finally {
            setProcessingPlans(prev => ({ ...prev, [plan.id]: false }));
          }
        },
        prefill: {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress,
        },
        modal: {
          ondismiss: function() {
            setProcessingPlans(prev => ({ ...prev, [plan.id]: false }));
          }
        }
      };
  
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error initiating upgrade:', error);
      toast.error(error.response?.data?.detail || 'Failed to initiate upgrade');
      setProcessingPlans(prev => ({ ...prev, [plan.id]: false }));
    }
  };
 
  const handleSubscriptionAction = (plan, isUpgrade) => {
    // Only call handleUpgrade if there's an active subscription and it's an upgrade
    if (hasActiveSubscription && isUpgrade) {
      handleUpgrade(plan);
    } else {
      handleSubscribe(plan);
    }
  };
  

  const handleCancelSubscription = async (subscriptionId) => {
    if (!window.confirm('Are you sure you want to cancel your subscription? This action cannot be undone.')) {
      return;
    }
  
    const loadingToastId = toast.loading('Cancelling subscription...');
  
    try {
      const token = await getToken({template: 'token'});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/cancel-razorpay-subscription`,
        {}, // Empty object as body
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      toast.dismiss(loadingToastId);
  
      if (response.data.success) {
        toast.success('Subscription cancelled successfully');
        await fetchSubscriptionHistory();
        await fetchCredits();
      } else {
        throw new Error(response.data.message || 'Failed to cancel subscription');
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error('Error cancelling subscription:', error);
      toast.error(error.response?.data?.detail || 'Failed to cancel subscription');
    }
  };
  const handlePayment = async (paymentType, credits, amount, planId = null) => {
    if (paymentType === 'oneTime') {
      setProcessingPayment(true);
    } else {
      setProcessingPlans(prev => ({ ...prev, [planId]: true }));
    }

    try {
      if (!razorpayKey) {
        throw new Error('Razorpay Key ID is not set');
      }

      const token = await getToken({template: 'token'});
      let response;
      if (paymentType === 'oneTime') {
        const amountInPaise = Math.round(amount * 100)*85;
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-razorpay-order`,
          { amount: amountInPaise },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-razorpay-subscription`,
          { plan_id: planId },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      const options = {
        key: razorpayKey,
        amount: response.data.amount,
        currency: response.data.currency,
        name: "FOOSH.ai",
        description: paymentType === 'oneTime' ? `Top up ${credits} credits` : `Subscribe to ${planId}`,
        order_id: response.data.id,
        subscription_id: paymentType === 'subscription' ? response.data.subscription_id : undefined,
        handler: async function (razorpayResponse) {
          try {
            const verifyToken = await getToken();
            const paymentData = {
              razorpay_payment_id: razorpayResponse.razorpay_payment_id,
              razorpay_signature: razorpayResponse.razorpay_signature,
              razorpay_order_id: razorpayResponse.razorpay_order_id,
              razorpay_subscription_id: razorpayResponse.razorpay_subscription_id,
              payment_type: paymentType
            };
            
            const verifyResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/verify-razorpay-payment`,
              paymentData,
              {
                headers: {
                  'Authorization': `Bearer ${verifyToken}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            
            if (verifyResponse.data.success) {

              if (paymentType === 'oneTime' && verifyResponse.data.status === 'captured') {
                navigate(`/payment-success?amount=${amount}&type=${paymentType}&credits=${credits}`);
              } else if (paymentType === 'subscription' && 
                ['active', 'authenticated'].includes(verifyResponse.data.status)) {
                navigate(`/payment-success?amount=${amount}&type=${paymentType}&credits=${credits}`);
              } else {
                navigate(`/payment-pending?amount=${amount}&type=${paymentType}&status=${verifyResponse.data.status}`);
              }
            } else {
              throw new Error(verifyResponse.data.message || 'Payment verification failed');
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
            const errorMessage = error.response?.data?.detail || error.response?.data?.message || error.message;
            navigate(`/payment-failure?amount=${amount}&type=${paymentType}&reason=${encodeURIComponent(errorMessage)}`);
          } finally {
            if (paymentType === 'oneTime') {
              setProcessingPayment(false);
            } else {
              setProcessingPlans(prev => ({ ...prev, [planId]: false }));
            }
          }
        },
        prefill: {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress,
        },
        modal: {
          ondismiss: function() {
            if (paymentType === 'oneTime') {
              setProcessingPayment(false);
            } else {
              setProcessingPlans(prev => ({ ...prev, [planId]: false }));
            }
          }
        }
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.detail || error.message;
        toast.error(`Error creating payment order: ${errorMessage}`);
      } else {
        toast.error('Error creating payment order. Please try again.');
      }
      if (paymentType === 'oneTime') {
        setProcessingPayment(false);
      } else {
        setProcessingPlans(prev => ({ ...prev, [planId]: false }));
      }
    }
  };

  const hasActiveSubscription = subscriptionHistory && (subscriptionHistory.sub1 || subscriptionHistory.sub2 || subscriptionHistory.sub3);
  return (
    <div className="max-w-6xl mx-auto p-4">
      <div className="grid grid-cols-3 gap-4 mb-8">
        {creditOptions.map((option, index) => (
          <div key={index} className="flex items-center bg-white p-4 rounded-lg shadow-md">
            <div className="text-blue-500">{option.icon}</div>
            <div className="ml-3">
              <p className="font-semibold">{option.name}</p>
              <p className="text-gray-600">{option.credits} credits</p>
            </div>
          </div>
        ))}
      </div>

      {hasActiveSubscription && (
        <div className="mb-12">
          <h3 className="text-2xl font-bold mb-6">Top up Credits</h3>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="flex items-center mb-4">
              <span className="text-2xl font-bold mr-2">$</span>
              <input
                type="text"
                value={topUpAmount}
                onChange={handleTopUpAmountChange}
                className="w-32 text-2xl font-bold border-b-2 border-gray-300 focus:outline-none focus:border-black"
                placeholder="10"
              />
              <span className="ml-4 text-gray-600">
                {calculateCredits(topUpAmount)} credits
              </span>
            </div>

            <button 
              onClick={handleTopUp}
              disabled={processingPayment || parseInt(topUpAmount) < 10}
              className={`px-6 py-3 rounded text-lg font-semibold w-full ${
                parseInt(topUpAmount) >= 10 && !processingPayment
                  ? 'bg-black text-white hover:bg-gray-800'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {processingPayment ? 'Processing...' : 'Pay with Razorpay'}
            </button>
          </div>
        </div>
      )}

      <div>
        <h2 className="text-2xl font-bold mb-6">Subscription Plans</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {monthlyPlans.map((plan, index) => {
            const planKey = `sub${index + 1}`;
            const isSubscribed = subscriptionHistory && subscriptionHistory[planKey];
            const isUpgrade = isUpgradePlan(index);
            
            return (
              <div key={plan.id} className="bg-white p-6 rounded-lg shadow-md">
                <div className="text-center mb-4">
                  <h3 className="text-xl font-bold">{plan.name} {plan.icon}</h3>
                  <div className="text-3xl font-bold my-4">
                    ₹{plan.price}
                    <span className="text-sm text-gray-500">/month</span>
                  </div>
                  <div className="text-lg text-gray-600 mb-4">
                    {plan.credits.toLocaleString()} credits monthly
                  </div>
                </div>

                <ul className="mb-6">
                  {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <Star className="w-5 h-5 mr-2 text-yellow-500" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                  {isSubscribed ? (
    <button
      disabled={true}
      className="w-full py-3 rounded-lg font-semibold bg-green-500 text-white cursor-not-allowed"
    >
      Currently Subscribed
    </button>
  ) : (
    <button
  onClick={() => handleSubscriptionAction(plan, isUpgradePlan(index))} // Pass the actual isUpgrade value
  disabled={processingPlans[plan.id]}
  className={`w-full py-3 rounded-lg font-semibold ${
    processingPlans[plan.id]
      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
      : isUpgrade
      ? 'bg-purple-500 text-white hover:bg-purple-600'
      : hasActiveSubscription
      ? 'bg-blue-500 text-white hover:bg-blue-600'
      : 'bg-black text-white hover:bg-gray-800'
  }`}
>
  {processingPlans[plan.id]
    ? 'Processing...'
    : isUpgrade
    ? 'Upgrade Plan'
    : hasActiveSubscription
    ? 'Change Plan'
    : 'Subscribe Now'}
</button>
  )}
                  
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-8 bg-gray-100 p-6 rounded-lg">
        <div className="text-center">
          <h4 className="font-bold text-xl mb-2">Enterprise Plan</h4>
          <p className="text-gray-600 mb-4">
            Custom solutions for teams with advanced needs. Get in touch for personalized pricing.
          </p>
          <button 
            className="bg-black text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-800 transition-colors"
            onClick={handleContactUs}
          >
            Contact Us
          </button>
        </div>
      </div>

      {showContactPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <div className="flex items-center mb-3">
              <Mail className="mr-2" size={20} />
              <a href="mailto:help@foosh.ai" className="text-blue-600 hover:underline">help@foosh.ai</a>
            </div>
            <div className="flex items-center mb-4">
              <Phone className="mr-2" size={20} />
              <span>+1 (555) 123-4567</span>
            </div>
            <button 
              className="bg-black text-white px-4 py-2 rounded w-full"
              onClick={() => setShowContactPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <p className="text-center text-sm text-gray-600 mt-8">
        Need help? Contact us at help@foosh.ai
      </p>
    </div>
  );
};

export default PaymentView;