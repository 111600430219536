import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { CreditCard, Camera, ZoomIn, CheckCircle } from 'lucide-react';
import { toast } from 'react-toastify';
import useCredits from '../hooks/useCredits';

const monthlyPlans = [
  { id: 'P-17U451794R2419509M4HLDAQ', name: 'HOBBY', price: 12, credits: 1000, icon: '🎨' },
  { id: 'P-7WA05698GW114015SM4IR4WI', name: 'CREATOR', price: 50, credits: 5000, icon: '🚀' },
  { id: 'P-9XY87654ZW3210987LMNOPQR', name: 'PRO', price: 240, credits: 25000, icon: '💼' },
  { id: 'plan_PMDpf0c9Dov38C', name: 'Starter', price: 1020, credits: 1000, icon: '🎨' },
  { id: 'plan_PMDq79Bpegqa93', name: 'Creator', price: 4250, credits: 5000, icon: '🚀' },
];

const creditOptions = [
  { name: 'Adding a model', credits: 800, icon: <CreditCard size={24} /> },
  { name: 'Create a shot', credits: 7, icon: <Camera size={24} /> },
  { name: 'Upscale a shot', credits: 30, icon: <ZoomIn size={24} /> },
];

function SubscriptionSuccessView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { fetchCredits } = useCredits();
  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get('plan_id');

  const selectedPlan = monthlyPlans.find(plan => plan.id === planId);

  useEffect(() => {
    const activateSubscription = async () => {
      if (selectedPlan) {
        try {
          const token = await getToken({template: 'token'});
          await axios.post(`${process.env.REACT_APP_API_URL}/subscription-success`, 
            { price: selectedPlan.price },
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          await fetchCredits();
        } catch (error) {
          console.error('Error updating credits:', error);
          toast.error('Failed to activate subscription. Please contact support.');
        }
      }
    };

    activateSubscription();
  }, [selectedPlan, getToken, fetchCredits]);

  const handleGoToStudio = useCallback(() => {
    navigate('/studio');
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
        <h1 className="text-3xl font-bold mb-4 text-green-600">
          🐠 Just keep Fooshin'! 🐠
        </h1>
        
        <p className="text-xl font-semibold mb-6">
          Subscription Activated!
        </p>

        {selectedPlan && (
          <>
            <div className="bg-green-50 border border-green-200 rounded-lg p-6 mb-6">
              <div className="flex items-center justify-center gap-2 mb-2">
                <CheckCircle className="text-green-500" size={24} />
                <span className="text-2xl font-bold">{selectedPlan.icon} {selectedPlan.name} PLAN</span>
              </div>
              <p className="text-gray-600 mb-2">
                {[1020, 4250].includes(selectedPlan.price) ? 
                  `₹${selectedPlan.price}/month` : 
                  `$${selectedPlan.price}/month`
                }
              </p>
              <p className="text-2xl font-semibold text-green-600">
                {selectedPlan.credits.toLocaleString()} credits/month
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-3">What you can do with your credits:</h3>
              <div className="grid grid-cols-3 gap-4">
                {creditOptions.map((option, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded">
                    <div className="text-3xl mb-2">{option.icon}</div>
                    <p className="font-semibold">{option.name}</p>
                    <p className="text-lg font-bold">{option.credits} credits</p>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        
        <p className="text-sm italic mb-6">
          Your credits refresh monthly with your subscription!
        </p>
        
        <button 
          className="w-full bg-black text-white py-3 rounded-lg mb-4 hover:bg-gray-800 transition-colors"
          onClick={handleGoToStudio}
        >
          Continue making epic shots
        </button>
      </div>
    </div>
  );
}

export default SubscriptionSuccessView;