import React from 'react';

const IconButton = ({ icon: Icon, onClick, title, disabled, color, fill, showBorder }) => (
    <button
        className={`p-2 rounded-full  bg-black   hover:bg-opacity-75 transition-all duration-200 ease-in-out group flex  justify-center items-center aspect-1   ${showBorder ? '  ' : ''
            }`}
        onClick={onClick}
        title={title}
        disabled={disabled}
    >
        <Icon size={20} className="text-white w-5 h-5 " color={color} fill={fill} />
        <span className="absolute bottom-full right-0 mb-2 hidden group-hover:block bg-black text-white text-xs py-1 px-2 rounded-md">
            {title}
        </span>
    </button>
);

export default IconButton;