import React, { useEffect, useState, useRef } from 'react';
import { Box, Image, ArrowUpCircle, Video } from 'lucide-react';
import { useAuth } from '@clerk/clerk-react';
import Lottie from 'react-lottie-player';
import inProgressAnimation from '../assets/icons/in_progress.json';
import idleAnimation from '../assets/icons/idle.json';
import DataTable from 'react-data-table-component';

const UsageView = () => {
    const formatDateForDisplay = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear().toString().slice(-2);
        return `${day} ${month}, '${year}`;
    };

    // Decoupled States:
    // "usageData" holds done data from /usage
    const [usageData, setUsageData] = useState({
        productsTrained: null,
        imagesGenerated: null,
        imagesUpscaled: null,
        videosGenerated: null,
    });
    // "liveStatus" holds in-progress data from /live_status
    const [liveStatus, setLiveStatus] = useState({
        trainings: 0,
        generations: 0,
        upscales: 0,
        videos: 0,
    });
    const [modelData, setModelData] = useState([]); // Ensure initial state is an array
    const [search, setSearch] = useState('');
    // Custom date-picker state:
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format

    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [appliedStartDate, setAppliedStartDate] = useState(today);
    const [appliedEndDate, setAppliedEndDate] = useState(today);
    const [dateRangeText, setDateRangeText] = useState(
        formatDateForDisplay(today) + ' - ' + formatDateForDisplay(today)
    );

    const [orgId, setOrgId] = useState("");

    const { getToken } = useAuth();
    const datePickerButtonRef = useRef(null);
    const datePickerDropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleApplyDates = () => {
        const formattedStart = formatDateForDisplay(startDate);
        const formattedEnd = formatDateForDisplay(endDate);
        setDateRangeText(`${formattedStart} to ${formattedEnd}`);
        // Update the applied dates so that the API post request is sent using the chosen range
        setAppliedStartDate(startDate);
        setAppliedEndDate(endDate);
        setDropdownVisible(false);
        setUsageData({
            productsTrained: null,
            imagesGenerated: null,
            imagesUpscaled: null,
            videosGenerated: null,
        });
        setModelData([]);
    };

    const handleCancelDates = () => {
        setDropdownVisible(false);
    };

    const handleQuickOption = (range) => {
        const today = new Date();
        let newStart = new Date();
        let newEnd = new Date();
        switch (range) {
            case 'today':
                // both are today
                break;
            case 'yesterday':
                newStart.setDate(today.getDate() - 1);
                newEnd.setDate(today.getDate() - 1);
                break;
            case 'last7':
                newStart.setDate(today.getDate() - 6);
                break;
            case 'last30':
                newStart.setDate(today.getDate() - 29);
                break;
            case 'thisMonth':
                newStart = new Date(today.getFullYear(), today.getMonth(), 1);
                break;
            case 'lastMonth':
                newStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                newEnd = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case 'last3Months':
                newStart = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
                break;
            default:
                break;
        }
        // Format for input fields (YYYY-MM-DD)
        const formatForInput = (d) => {
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        setStartDate(formatForInput(newStart));
        setEndDate(formatForInput(newEnd));
    };

    const StableLottie = React.memo(({ animationData, style }) => {
        return (
            <Lottie
                loop
                animationData={animationData}
                play
                style={style}
            />
        );
    });

    // Close date-picker dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                datePickerButtonRef.current &&
                !datePickerButtonRef.current.contains(event.target) &&
                datePickerDropdownRef.current &&
                !datePickerDropdownRef.current.contains(event.target)
            ) {
                setDropdownVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const formatDateForPayload = (dateStr) => {
            // Convert from "YYYY-MM-DD" to "DD/MM/YYYY"
            const [year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        };

        const fetchUsageData = async () => {
            try {
                const token = await getToken({ template: 'token' });
                const payload = {
                    start_date: formatDateForPayload(appliedStartDate),
                    end_date: formatDateForPayload(appliedEndDate)
                };
                console.log('Request Payload:', payload);

                const response = await fetch(`${process.env.REACT_APP_API_URL}/usage`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log('API Response:', data);
                setUsageData({
                    productsTrained: data.trainings,
                    imagesGenerated: data.images,
                    imagesUpscaled: data.upscales,
                    videosGenerated: data.videos,
                });
                // Parse model_data string into an array
                const parsedModelData = JSON.parse(data.model_data);
                setModelData(Array.isArray(parsedModelData) ? parsedModelData : []);
            } catch (error) {
                console.error('Error fetching usage data:', error);
            }
        };

        fetchUsageData();
    }, [getToken, appliedStartDate, appliedEndDate]);

    useEffect(() => {
        async function fetchOrgId() {
            const token = await getToken({ template: 'token' });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/org_id`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setOrgId(data.org_id);
        }
        fetchOrgId();
    }, [getToken]);

    useEffect(() => {
        if (!orgId) return; // Wait until orgId is available.
        let eventSource;
        eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/live_status?org_id=${orgId}`);
        eventSource.onmessage = (event) => {
            const liveData = JSON.parse(event.data);
            setLiveStatus({
                trainings: liveData.trainings,
                generations: liveData.generations,
                upscales: liveData.upscales,
                videos: liveData.videos,
            });
        };
        eventSource.onerror = (err) => {
            console.error('EventSource error:', err);
            eventSource.close();
        };
        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [orgId]);

    const filteredData = modelData.filter(item =>
        item[1].toLowerCase().includes(search.toLowerCase())
    );

    const columns = [
        {
            name: 'Model Name',
            selector: row => row[1],
            sortable: true,
            cell: row => <div className="whitespace-normal break-words">{row[1]}</div>
        },
        {
            name: 'Date',
            selector: row => row[2],
            sortable: true,
            sortFunction: (rowA, rowB) => {
                const dateA = new Date(rowA[2].split('-').reverse().join('-'));
                const dateB = new Date(rowB[2].split('-').reverse().join('-'));
                return dateA - dateB;
            }
        },
        { name: 'Generations', selector: row => row[3], sortable: true },
        { name: 'Upscales', selector: row => row[4], sortable: true },
        { name: 'Videos', selector: row => row[5], sortable: true },
    ];

    return (
        <div className="w-3/4 mx-auto p-8">
            {/* Updated header */}
            <div className="header mb-4 flex justify-between items-center">
                <div className="title text-2xl font-bold">Usage</div>
                <div className="date-picker-container relative">
                    <div
                        className="date-picker-button flex items-center justify-between w-72 px-4 py-2 border border-gray-300 rounded-lg bg-white cursor-pointer"
                        onClick={toggleDropdown}
                        ref={datePickerButtonRef}
                    >
                        <span id="dateRangeText">{dateRangeText}</span>
                        <span>▼</span>
                    </div>
                    <div
                        className={`date-picker-dropdown absolute right-0 top-10 w-72 p-4 border border-gray-300 rounded-lg bg-white shadow-lg z-50 ${dropdownVisible ? "block" : "hidden"}`}
                        ref={datePickerDropdownRef}
                    >
                        <div className="quick-select flex flex-wrap gap-1 mb-4">
                            <div className="quick-option px-2 py-1 bg-gray-200 rounded cursor-pointer text-sm" onClick={() => handleQuickOption('today')}>Today</div>
                            <div className="quick-option px-2 py-1 bg-gray-200 rounded cursor-pointer text-sm" onClick={() => handleQuickOption('yesterday')}>Yesterday</div>
                            <div className="quick-option px-2 py-1 bg-gray-200 rounded cursor-pointer text-sm" onClick={() => handleQuickOption('last7')}>Last 7 days</div>
                            <div className="quick-option px-2 py-1 bg-gray-200 rounded cursor-pointer text-sm" onClick={() => handleQuickOption('last30')}>Last 30 days</div>
                            <div className="quick-option px-2 py-1 bg-gray-200 rounded cursor-pointer text-sm" onClick={() => handleQuickOption('thisMonth')}>This month</div>
                            <div className="quick-option px-2 py-1 bg-gray-200 rounded cursor-pointer text-sm" onClick={() => handleQuickOption('lastMonth')}>Last month</div>
                            <div className="quick-option px-2 py-1 bg-gray-200 rounded cursor-pointer text-sm" onClick={() => handleQuickOption('last3Months')}>Last 3 months</div>
                        </div>
                        <div className="date-group mb-2">
                            <label className="date-label block text-sm text-gray-600 mb-1">Start date:</label>
                            <input
                                type="date"
                                className="date-input w-full p-2 border border-gray-300 rounded"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="date-group mb-4">
                            <label className="date-label block text-sm text-gray-600 mb-1">End date:</label>
                            <input
                                type="date"
                                className="date-input w-full p-2 border border-gray-300 rounded"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                        <div className="date-actions flex justify-between">
                            <button
                                className="date-btn cancel-btn px-4 py-2 bg-gray-200 rounded text-sm"
                                onClick={handleCancelDates}
                            >
                                Cancel
                            </button>
                            <button
                                className="date-btn apply-btn px-4 py-2 bg-blue-600 text-white rounded text-sm"
                                onClick={handleApplyDates}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* End header */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                {/* Trainings Card */}
                <div className="flex flex-col justify-between p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 bg-white">
                    <div className="flex items-center mb-2">
                        <Box className="mr-2" />
                        <span className="font-medium">Trainings</span>
                    </div>
                    <span className="text-3xl font-bold">
                        {usageData.productsTrained === null ? (
                            <StableLottie animationData={inProgressAnimation} style={{ width: 40, height: 40 }} />
                        ) : (
                            usageData.productsTrained
                        )}
                    </span>
                    <div className="flex justify-between items-center mt-2">
                        <span className={`flex items-center gap-1 px-1 py-1 rounded text-sm ${liveStatus.trainings !== 0 ? 'bg-green-200 text-green-800' : 'bg-gray-600 text-white'}`}>
                            {liveStatus.trainings === 0 ? (
                                <StableLottie animationData={idleAnimation} style={{ width: 24, height: 25 }} />
                            ) : (
                                <StableLottie animationData={inProgressAnimation} style={{ width: 25, height: 25 }} />
                            )}
                            {liveStatus.trainings === 0 ? "Idle" : `${liveStatus.trainings} in progress`}
                        </span>
                    </div>
                </div>
                {/* Generations Card */}
                <div className="flex flex-col justify-between p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 bg-white">
                    <div className="flex items-center mb-2">
                        <Image className="mr-2" />
                        <span className="font-medium">Generations</span>
                    </div>
                    <span className="text-3xl font-bold">
                        {usageData.imagesGenerated === null ? (
                            <StableLottie animationData={inProgressAnimation} style={{ width: 40, height: 40 }} />
                        ) : (
                            usageData.imagesGenerated
                        )}
                    </span>
                    <div className="flex justify-between items-center">
                        <span className={`flex items-center gap-1 px-1 py-1 rounded text-sm ${liveStatus.generations !== 0 ? 'bg-green-200 text-green-800' : 'bg-gray-600 text-white'}`}>
                            {liveStatus.generations !== 0 ? (
                                <StableLottie animationData={inProgressAnimation} style={{ width: 25, height: 25 }} />
                            ) : (
                                <StableLottie animationData={idleAnimation} style={{ width: 25, height: 25 }} />
                            )}
                            {liveStatus.generations !== 0 ? `${liveStatus.generations} in progress` : "Idle"}
                        </span>
                    </div>
                </div>
                {/* Upscales Card */}
                <div className="flex flex-col justify-between p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 bg-white">
                    <div className="flex items-center mb-2">
                        <ArrowUpCircle className="mr-2" />
                        <span className="font-medium">Upscales</span>
                    </div>
                    <span className="text-3xl font-bold">
                        {usageData.imagesUpscaled === null ? (
                            <StableLottie animationData={inProgressAnimation} style={{ width: 40, height: 40 }} />
                        ) : (
                            usageData.imagesUpscaled
                        )}
                    </span>
                    <div className="flex justify-between items-center mt-2">
                        <span className={`flex items-center gap-1 px-1 py-1 rounded text-sm ${liveStatus.upscales !== 0 ? 'bg-green-200 text-green-800' : 'bg-gray-600 text-white'}`}>
                            {liveStatus.upscales !== 0 ? (
                                <StableLottie animationData={inProgressAnimation} style={{ width: 25, height: 25 }} />
                            ) : (
                                <StableLottie animationData={idleAnimation} style={{ width: 25, height: 25 }} />
                            )}
                            {liveStatus.upscales !== 0 ? `${liveStatus.upscales} in progress` : "Idle"}
                        </span>
                    </div>
                </div>
                {/* Videos Card */}
                <div className="flex flex-col justify-between p-4 border border-gray-300 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200 bg-white">
                    <div className="flex items-center mb-2">
                        <Video className="mr-2" />
                        <span className="font-medium">Videos</span>
                    </div>
                    <span className="text-3xl font-bold">
                        {usageData.videosGenerated === null ? (
                            <StableLottie animationData={inProgressAnimation} style={{ width: 40, height: 40 }} />
                        ) : (
                            usageData.videosGenerated
                        )}
                        </span>
                    <div className="flex justify-between items-center mt-2">
                        <span className={`flex items-center gap-1 px-1 py-1 rounded text-sm ${liveStatus.videos !== 0 ? 'bg-green-200 text-green-800' : 'bg-gray-600 text-white'}`}>
                            {liveStatus.videos !== 0 ? (
                                <StableLottie animationData={inProgressAnimation} style={{ width: 25, height: 25 }} />
                            ) : (
                                <StableLottie animationData={idleAnimation} style={{ width: 25, height: 25 }} />
                            )}
                            {liveStatus.videos !== 0 ? `${liveStatus.videos} in progress` : "Idle"}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-8">
                <h2 className="text-2xl font-bold mb-4">Usage by Model</h2>
                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    highlightOnHover
                    paginationPerPage={50} // Set default rows per page to 50
                    paginationComponentOptions={{
                        noRowsPerPage: true, // Hide the rows per page dropdown
                        rangeSeparatorText: 'of',
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'All',
                    }}
                    subHeader
                    subHeaderComponent={
                        <input
                            type="text"
                            placeholder="Search by model name"
                            className="mb-4 p-2 border border-gray-300 rounded-lg w-1/4"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default UsageView;