import React from 'react';
import { useNavigate } from 'react-router-dom';

const TrainNewSuccessView = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
            <div className="max-w-2xl text-center p-8">
                <div className="mb-8">
                    <h1 className="text-3xl font-bold mb-4">🎉 Your model is being trained!</h1>
                    <p className="text-xl text-gray-600 mb-6">
                        We'll notify you once your model is ready to use.
                    </p>
                    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                        <h2 className="text-lg font-semibold mb-4">What happens next?</h2>
                        <ul className="text-left space-y-3">
                            <li className="flex items-start">
                                <span className="mr-2">1.</span>
                                <span>Your photos are being processed and analyzed</span>
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2">2.</span>
                                <span>AI model training will begin shortly</span>
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2">3.</span>
                                <span>Training typically takes 2-3 hours</span>
                            </li>
                            <li className="flex items-start">
                                <span className="mr-2">4.</span>
                                <span>You'll receive a notification when your model is ready</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <button
                    onClick={() => navigate('/models')}
                    className="bg-black text-white px-8 py-3 rounded-lg font-semibold hover:bg-gray-800 transition-colors"
                >
                    View My Models
                </button>
            </div>
        </div>
    );
};

export default TrainNewSuccessView;