import React, { useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import throttle from 'lodash.throttle';
import lottiesSrc from '../../assets/icons/loaderlottie.gif'
import GeneratedImage from '../GeneratedImage';

const ShimmerEffect = ({ aspectHeight, aspectWidth, imageIndex }) => (
  <div className="rounded-[20px] w-full overflow-hidden relative " style={{ aspectRatio: `${aspectWidth} / ${aspectHeight}` }}><img className="object-cover w-full h-full" src={lottiesSrc} alt='' /> <div className={`${imageIndex === 0 ? "absolute z-[10] top-4 left-4 text-[.8rem] px-2 py-1 bg-white  rounded-md " : "none"}`}>Shots ready in ~2 mins </div></div>
);

const GenerationGroup = ({
  generationId,
  aspectRatio,
  images,
  prompt,
  setSelectedImage,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLastGroup,
  showPrompt,
  setUpscaleQueue,
  setCompletedUpscale
}) => {

  const throttledFetchNextPage = useRef(
    throttle(() => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, 2000) // Adjust the delay (in milliseconds) as needed
  ).current;

  const lastImageRef = useCallback(
    node => {
      if (node !== null) {
        const observer = new IntersectionObserver(
          entries => {
            if (entries[0].isIntersecting) {
              throttledFetchNextPage();
            }
          },
          { threshold: 0.1 }
        );
        observer.observe(node);
        return () => observer.disconnect();
      }
    },
    [throttledFetchNextPage]
  );

  const handleCopyPrompt = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log("Prompt copied to clipboard!");
        toast.success("Prompt copied to clipboard!", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((error) => {
        console.error("Failed to copy prompt: ", error);
      });
  };

  const [aspectWidth, aspectHeight] = aspectRatio?.split(':').map(Number);
  return (
    <div key={generationId} className="mb-4 ">
      {showPrompt &&
        <div className="text-sm mb-2 text-ellipsis flex gap-2 ml-2">
          {/* <Copy className="text-sm p-[3px] cursor-pointer" onClick={() => handleCopyPrompt(prompt)} /> */}
          <p>{prompt}</p>
        </div>
      }
      <div className="grid grid-cols-4 gap-4">
        {images.map((image, imageIndex) => {
          return (
            <div
              ref={isLastGroup ? lastImageRef : null}
              className="relative flex  w-full rounded-[20px] bg-gradient-to-b from-white to-gray-200  "
              style={{ aspectRatio: `${aspectWidth} / ${aspectHeight}` }}
            >
              {image ? (
                <GeneratedImage
                  thumbnailUrl={image.image_url}
                  image={image}
                  setSelectedImage={setSelectedImage}
                  setUpscaleQueue={setUpscaleQueue}
                  setCompletedUpscale={setCompletedUpscale}
                />
              ) : (
                <ShimmerEffect imageIndex={imageIndex} aspectWidth={aspectWidth} aspectHeight={aspectHeight} />
              )}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default GenerationGroup;
