// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.studio-container {

  display: flex;
  flex-direction: column;
  /* Stack the content vertically */
  /* height: 90vh; Full height of the viewport */
}

.instruction-panel {
  flex: 1 1;
  /* Make each row take up equal space */
  padding: 20px;
}


.generation-panel {
  flex: 7 1;
  /* Make each row take up equal space */
  /* padding: 20px; */
}

.divider {
  height: 1px;
  /* Thin grey line */
  background-color: lightgray;
  width: 100%;
  /* Full viewport width */
}`, "",{"version":3,"sources":["webpack://./src/views/StudioView.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,sBAAsB;EACtB,iCAAiC;EACjC,8CAA8C;AAChD;;AAEA;EACE,SAAO;EACP,sCAAsC;EACtC,aAAa;AACf;;;AAGA;EACE,SAAO;EACP,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,wBAAwB;AAC1B","sourcesContent":[".studio-container {\n\n  display: flex;\n  flex-direction: column;\n  /* Stack the content vertically */\n  /* height: 90vh; Full height of the viewport */\n}\n\n.instruction-panel {\n  flex: 1;\n  /* Make each row take up equal space */\n  padding: 20px;\n}\n\n\n.generation-panel {\n  flex: 7;\n  /* Make each row take up equal space */\n  /* padding: 20px; */\n}\n\n.divider {\n  height: 1px;\n  /* Thin grey line */\n  background-color: lightgray;\n  width: 100%;\n  /* Full viewport width */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
