import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { toast } from 'react-toastify';
import useCredits from '../hooks/useCredits';
import CreditAddModelBag from '../assets/icons/AddModelCreditSystem@3x.jpg';
import CreatShotCreditSystem from '../assets/icons/CreateShotCreditSystem@3x.png';
import UpscaleShotCreditSystem from '../assets/icons/UpscaleShotCreditSystem@3x.png';

const monthlyPlans = [
  {
    id: 'P-17U451794R2419509M4HLDAQ',
    name: 'Starter',
    price: 12,
    credits: 1000,
    icon: '🎨',
  },
  {
    id: 'P-7WA05698GW114015SM4IR4WI',
    name: 'Creator',
    price: 50,
    credits: 5000,
    icon: '🚀',
  },
  {
    id: 'P-9XY87654ZW3210987LMNOPQR',
    name: 'Pro',
    price: 240,
    credits: 25000,
    icon: '💼',
  },
  {
    id: 'plan_PMEwtUkKIURTua',
    name: 'Starter',
    price: 1020,
    credits: 1000,
    icon: '🎨',
  },
  {
    id: 'plan_PMFhKFLkg87PLj',
    name: 'Creator',
    price: 4250,
    credits: 5000,
    icon: '🚀',
  },
];

const creditOptions = [
  { name: 'Training a model', credits: 800, icon: CreditAddModelBag },
  { name: 'Generate an image', credits: 7, icon: CreatShotCreditSystem },
  { name: 'Upscale an image', credits: 30, icon: UpscaleShotCreditSystem },
];

function SubscriptionSuccessView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { fetchCredits } = useCredits();
  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get('plan_id');
  const subscriptionId = searchParams.get('subscription_id'); 
  const mode = searchParams.get('mode');


  const selectedPlan = monthlyPlans.find(plan => plan.id === planId);

  useEffect(() => {
    const activateSubscription = async () => {
      if (mode === 'rzp') {
        return;
      }
      if (selectedPlan && subscriptionId) {
        try {
          const token = await getToken({ template: 'token' });

          // Call both endpoints simultaneously
          const [statusResponse, activationResponse] = await Promise.all([
            axios.get(
              `${process.env.REACT_APP_API_URL}/xpay-sub-status/${subscriptionId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            ),
            axios.post(
              `${process.env.REACT_APP_API_URL}/subscription-success`,
              { price: selectedPlan.price },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              }
            ),
          ]);

          await fetchCredits();
        } catch (error) {
          console.error('Error activating subscription:', error);
          toast.error(
            'Failed to activate subscription. Please contact support.'
          );
        }
      }
    };

    activateSubscription();
  }, [selectedPlan, subscriptionId, getToken, fetchCredits]);
  const handleGoToStudio = useCallback(() => {
    navigate('/studio');
  }, [navigate]);
  const getFormattedDate = () => {
    const today = new Date();
  
    // Add 1 to the month
    const nextMonthDate = new Date(
      today.getFullYear(),
      today.getMonth() + 1, // Add 1 to the current month
      today.getDate()
    );
  
    const day = nextMonthDate.getDate();
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[nextMonthDate.getMonth()]; // Get month name
    const year = nextMonthDate.getFullYear();
  
    // Add ordinal suffix to the day
    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th–13th
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };
  
    return `${day}${ordinalSuffix(day)}, ${month}, ${year}`;
  };
  
  return (
    <div className="flex flex-col items-center justify-center  flex-1 relative overflow-hidden ">
      {/* <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center"> */}
      {/* <h1 className="text-3xl font-bold mb-4 text-green-600">
          🐠 Just keep Fooshin'! 🐠
        </h1>
        
        <p className="text-xl font-semibold mb-6">
          Subscription Activated!
        </p> */}

      {selectedPlan && (
        <>
          <div className=" rounded-lg px-6  pt-6   text-center">
            <div className="flex items-center justify-center gap-2 mb-2">
              {/* <CheckCircle className="text-green-500" size={24} /> */}
              <span className="text-sm font-bold ">
                {' '}
                Welcome to your Foosh {selectedPlan.name} plan
              </span>
            </div>
            {/* <p className="text-gray-600 mb-2">
                {[1020, 4250].includes(selectedPlan.price) ? 
                  `₹${selectedPlan.price}/month` : 
                  `$${selectedPlan.price}/month`
                }
              </p> */}
            <p className="text-5xl font-semibold ">
              {selectedPlan.credits.toLocaleString()} credits added!
            </p>
            <p className="text-lg p-2">Renews on {getFormattedDate()}</p>
          </div>

          <div className="flex flex-col mt-4  border-b-[1px] ">
            {/* <div className="font-bold text-lg">How credits work</div> */}
            <div className="grid grid-cols-3 gap-4 mb-4">
              {creditOptions.map((option, index) => (
                <div key={index} className="flex items-center bg-white p-4 ">
                  <img className=" w-16" src={option.icon} alt=""></img>
                  <div className=" p-2">
                    <p className="text-gray-600 text-sm">{option.name}</p>
                    <p className=" font-semibold text-xl">
                      {option.credits} credits
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {/* <p className="text-sm italic mb-6">
          Your credits refresh monthly with your subscription!
        </p> */}

      <button
        className="flex p-8 text-xl px-40 font-semibold mt-6 bg-black text-white py-3 rounded-lg mb-4 hover:bg-gray-800 transition-colors"
        onClick={handleGoToStudio}
      >
        Continue making epic shots
      </button>
      {/* </div> */}
      <div
        className="absolute overflow-hidden opacity-0 shadow-lg -bottom-32 w-[233px] h-[233px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '-14deg', animationDelay: '0.2s' }}
      > <img className="w-full h-full object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success3.png" alt=""/></div>
      <div
        className="absolute overflow-hidden shadow-lg opacity-0 -bottom-10 -left-24 w-[335px] h-[335px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '16deg', animationDelay: '0.4s' }}
      > <img className="w-full h-full  object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success1.png" alt=""/></div>
      <div
        className="absolute overflow-hidden opacity-0 -bottom-24 -right-24 w-[335px] h-[335px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '-20deg', animationDelay: '0.6s' }}
      > <img className="w-full h-full  object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success5.png" alt=""/></div>
      <div
        className="absolute overflow-hidden opacity-0 -bottom-24 left-80 w-[127px] h-[170px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '-8deg', animationDelay: '0.8s' }}
      > <img className="w-full h-full object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success2.png" alt=""/></div>
      <div
        className="absolute overflow-hidden opacity-0 -bottom-24 right-80 w-[174px] h-[174px]  rounded-2xl animate-slide-in"
        style={{ '--rotate': '8deg', animationDelay: '1s' }}
      >
        <img className="w-full h-full object-cover" src="https://d2h4y8xaq7mn9l.cloudfront.net/success4.png" alt=""/>
      </div>
    </div>
  );
}

export default SubscriptionSuccessView;
