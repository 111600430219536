// components/TrainingGuidelinesSection.jsx
import React from 'react';
import DoAndDontSection from './DoAndDontSection';
import {
    productDos,
    productDonts,
    personDos,
    personDonts,
    petDos,
    petDonts
} from '../../constants/doAndDontSectionConsts';

const TrainingGuidelinesSection = ({
    productType,
    setProductType,
    fileInputRef,
    handleImageUpload,
    eyeWear=false
}) => {
    const getDosAndDonts = () => {
        switch (productType) {
            case 'product':
                return { dos: productDos, donts: productDonts };
            case 'person':
                return { dos: personDos, donts: personDonts };
            case 'pet':
                return { dos: petDos, donts: petDonts };
            default:
                return { dos: productDos, donts: productDonts };
        }
    };

    return (
        <div className="flex flex-col flex-1">
            <div className="flex w-full justify-center font-[500] my-4">
                {eyeWear ?
                <><div className="grid grid-cols-3 w-1/4 justify-center  font-[400]  mt-2 rounded-full text-center">
                    <div></div>
                <div
                    className={`cursor-pointer py-3 px-2 rounded-full ease-in-out transition-colors duration-300 ${productType === 'product'
                        ? 'bg-black text-white'
                        : 'bg-white-0 text-gray-400'
                        }`}
                    onClick={() => setProductType('product')}
                >
                    Product
                </div>
            </div></>:
                <div className="grid grid-cols-3 w-1/4 font-[400] bg-[#F0F0F0] mt-2 rounded-full text-center">
                    <div
                        className={`cursor-pointer py-3 px-2 rounded-full ease-in-out transition-colors duration-300 ${productType === 'product'
                            ? 'bg-black text-white'
                            : 'bg-white-0 text-gray-400'
                            }`}
                        onClick={() => setProductType('product')}
                    >
                        Product
                    </div>
                    <div
                        className={`cursor-pointer py-3 px-2 rounded-full ease-in-out transition-colors duration-300 ${productType === 'person'
                            ? 'bg-black text-white'
                            : 'bg-white-0 text-gray-400'
                            }`}
                        onClick={() => setProductType('person')}
                    >
                        Person
                    </div>
                    <div
                        className={`cursor-pointer py-3 px-2 rounded-full ease-in-out transition-colors duration-300 ${productType === 'pet'
                            ? 'bg-black text-white'
                            : 'bg-white-0 text-gray-400'
                            }`}
                        onClick={() => setProductType('pet')}
                    >
                        Pet
                    </div>
                </div>
                }
            </div>

            {
                productType !== "product" && (
                    <div className="text-center my-2">
                        Here's an example of a good set of pictures to upload.
                    </div>
                )
            }

            <DoAndDontSection {...getDosAndDonts()} />

            {/* Image Upload Section */}
            <div className="w-full bg-white z-20 border-gray-300 p-2 flex flex-col justify-center items-center">
                <label
                    htmlFor="file-upload"
                    className="bg-black text-white py-2 px-16 rounded-full cursor-pointer"
                >
                    <div className="flex w-full h-full gap-2 justify-center text-center">
                        <div className='flex flex-col'>
                            <div className="flex w-full text-lg justify-center font-bold">
                                Upload 5-10 pictures of your model
                            </div>
                            <div className="flex w-full text-xs tracking-tight">
                                PNG or JPEG, Minimum resolution of 512x512, Max 10MB size per image
                            </div>
                        </div>
                    </div>
                </label>
                <input
                    id="file-upload"
                    type="file"
                    ref={fileInputRef}
                    multiple
                    accept="image/png, image/jpeg"
                    className="hidden"
                    onChange={handleImageUpload}
                />
            </div>
        </div>
    );
};

export default TrainingGuidelinesSection;